import React, { useState } from "react";
import {
	Input,
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Row,
	Badge, Offcanvas, OffcanvasBody
} from "reactstrap";
import { isEmpty } from "lodash";
import * as moment from "moment";
import { useAsyncDebounce } from "react-table";
import { useTranslation, initReactI18next } from "react-i18next";
import Flatpickr from "react-flatpickr";
import 'react-toastify/dist/ReactToastify.css';
import * as FileSaver from "file-saver";
import Cookies from 'js-cookie';
import axios from "axios";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Pagination from "../../Components/Commons/Pagination";
import WebServiceTable from "./WebServiceTable";

import { 
	useGetWebServicesQuery,
	
 } from '../../api/api'

const WebServices = () => {

	const { t } = useTranslation();

	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState(null);
	const [creationStartDate, setCreationStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);

	const [isOpen, setIsOpen] = useState(false);
	const [sideBarWebService, setSideBarWebService] = useState(null);
	const [loadingDownload, setLoadingDownload] = useState(false);
	
	const { 
		data: webServicesPages,
		error,
		isFetching: iswebServiceLoading,
		isSuccess: isWebServiceSuccess
	} = useGetWebServicesQuery(
		{ 
			page: currentPage,
			filter: search,
			creationStartDate: creationStartDate,
			creationEndDate: creationEndDate,
		}, 
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const onChange = useAsyncDebounce((value) => {
		setSearch(value);
	}, 200);

	const handleWebServiceExport = (ws) => {
		let token = JSON.parse(Cookies.get("authUser")).token;
		setLoadingDownload(true);
		axios.get(`send-inputs/${ws.last_send_input.reference}/export_data/`, 
			{
				headers: { 'Authorization': `Token ${token}` },
				responseType: 'blob'
			}
		).then((response) => {
			setLoadingDownload(false);
			if(ws.protocol_kind === 2){
				saveAs(response, `payload_${Date.now()}.xml`);
			} else {
				saveAs(response, `payload_${Date.now()}.json`);
			}
		});
	};

	/*const handleSendInputExport = (send_input) => {
		let token = JSON.parse(Cookies.get("authUser")).token;
		axios.get(`send-inputs/${send_input.last_send_input.reference}/export_data/`, 
			{
				headers: { 'Authorization': `Token ${token}` },
				responseType: 'blob'
			}
		).then((response) => {
			if(send_input.web_service.protocol_kind === 2){
				saveAs(response, `payload_${Date.now()}.xml`);
			} else {
				saveAs(response, `payload_${Date.now()}.json`);
			}
		});
	};*/

	document.title = t("WebService Data Replication");
	
	return (
		<div className="page-content">
			<Container fluid>
				<Offcanvas
					isOpen={isOpen}
					direction="end"
					toggle={() => setIsOpen(!isOpen)}
					className="offcanvas-end border-0"
					tabIndex="-1"
					id="member-overview"
				>
					<OffcanvasBody className="profile-offcanvas p-0">
						<div className="p-3">
							<h5 className="fs-15 mb-3">{t("Web Service Details")}</h5>
							<div className="mb-3">
								<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Payload Sent")}</p>
								<h6>
									{sideBarWebService && sideBarWebService.last_send_input ? sideBarWebService.last_send_input.payload : ''}
								</h6>
							</div>
							<div className="mb-3">
								<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Web Service Return")}</p>
								<h6>
									{sideBarWebService && sideBarWebService.last_send_input ? JSON.stringify(sideBarWebService.last_send_input.response, null, 2) : ''}
								</h6>
							</div>
							<div className="mb-3">
								<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Error Response")}</p>
								<h6>
									{sideBarWebService ? sideBarWebService.errors : ''}
								</h6>
							</div>
						</div>
					</OffcanvasBody>
					<div className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
						<button 
							className="btn btn-primary w-100"
							onClick={() => handleWebServiceExport(sideBarWebService)}
							>
							<i className="ri-check-line align-bottom ms-1"></i>
							{t("Download")}
						</button>
					</div>
				</Offcanvas>
				<BreadCrumb title={t("Web Services list")} pageTitle={t("Web Services")} />
				<Row>
					<Col lg={12}>
						<Card id="orderList">
							<CardHeader className="border-0">
								<Row className="align-items-center gy-3">
									<div className="col-sm">
										<h5 className="card-title mb-0">{t("Web Services")} <Badge color="primary" className="ms-1">{webServicesPages != null ? webServicesPages.count : '...'}</Badge></h5>
									</div>
								</Row>
							</CardHeader>
							<CardBody className="pt-0">
								<div>
									<Row className="mb-3">
										<CardBody className="border border-dashed border-end-0 border-start-0">
											<form>
												<Row>
													<Col md={3}>
														<Input
															name="orderId"
															id="id-field"
															className="form-control"
															placeholder={t("Search")}
															type="text"
															onChange={(e) => {
																setCurrentPage(1);
																onChange(e.target.value);
															}}
														/>
													</Col>

													<Col md={3}>
														<Flatpickr
															className="form-control"
															id="datepicker-created-at"
															placeholder={t("Created at")}
															options={{
																altInput: true,
																altFormat: "F j, Y",
																mode: "range",
																dateFormat: "DD-MM-YYYY",
															}}
															onChange={(date) => {
																setCurrentPage(1);
																if(date[0]!== undefined){
																	const date1 = moment(date[0]).format("DD-MM-YYYY");
																	setCreationStartDate(date1);
																} else {
																	setCreationStartDate(null);
																}
																if(date[1]!== undefined){
																	const date1 = moment(date[1]).format("DD-MM-YYYY");
																	setCreationEndDate(date1);
																} else {
																	setCreationEndDate(null);
																}
															}}
														/>
													</Col>
												</Row>
											</form>
										</CardBody>
									</Row>
									<WebServiceTable
										data={webServicesPages != null ? webServicesPages.results : []}
										isLoading={iswebServiceLoading}
										isSuccess={isWebServiceSuccess}
										error={error}
										onDetailsPressed={(webService) => {
											setSideBarWebService(webService);
											setIsOpen(true);
										}}
										onDownloadPressed={(webService) => {
											handleWebServiceExport(webService);
										}}
									/>
								</div>
								{webServicesPages && !isEmpty(webServicesPages.results) && (
									<Pagination
										totalRows={webServicesPages.count}
										pageChangeHandler={setCurrentPage}
										rowsPerPage={25}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default WebServices;

