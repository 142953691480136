import React, { useState, useEffect, useCallback } from 'react';
import {  useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, FormFeedback } from 'reactstrap';
import classnames from "classnames";
import { Range, getTrackBackground } from 'react-range';
import { useTranslation } from "react-i18next";
import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";
import { toast } from 'react-toastify';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { useGetSettingQuery, useUpdateSettingMutation } from '../../api/api'

//import images
import progileBg from '../../images/ws.jpg';

const STEP = 1;
const MIN = 0;
const MAX = 60;
const COLORS = getChartColorsArray('["--vz-success", "--vz-warning", "--vz-danger"]');

const SystemSettings = () => {

	let { id } = useParams();
	const { t } = useTranslation();

	const [gpsValues, setGpsValues] = React.useState([10, 20]);
	const [webServiceValues, setWebServiceValues] = React.useState([10, 20]);

	const [activeTab, setActiveTab] = useState("1");

	const tabChange = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const { 
		data: settings,
		error,
		isFetching: isSettingLoading,
		isSuccess: isSettingSuccess
	} = useGetSettingQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

  useEffect(() => {
    if (settings) {

			const gps_updated_limit_updated = settings.filter(setting => setting.name === "gps_updated_limit_updated")[0];
			const gps_updated_limit_warning = settings.filter(setting => setting.name === "gps_updated_limit_warning")[0];

			setGpsValues([
				gps_updated_limit_updated.value, 
				gps_updated_limit_warning.value]);

			const web_service_updated_limit_updated = settings.filter(setting => setting.name === "web_service_updated_limit_updated")[0];
			const web_service_updated_limit_warning = settings.filter(setting => setting.name === "web_service_updated_limit_warning")[0];

			setWebServiceValues([
				web_service_updated_limit_updated.value, 
				web_service_updated_limit_warning.value]);
    }
  }, [settings]);

	const [
		updateSetting, 
		{ 
			isSuccess: updateSuccess,
			error: updateErrors,
			isLoading: isUpdateLoading
		}] = useUpdateSettingMutation();

		useEffect(() => {
			if (updateSuccess) {
				toast.success(t(`Settings successfully saved`), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",}
				);
			}
			if (updateErrors) {
				toast.error(t(`Problem saving settings`), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",}
				);
			}
		}, [updateSuccess, updateErrors]);

	const doUpdateSettings = useCallback(() => {
    const settings = {
			gps_updated_limit_updated: gpsValues[0],
			gps_updated_limit_warning: gpsValues[1],
			web_service_updated_limit_updated: webServiceValues[0],
			web_service_updated_limit_warning: webServiceValues[1],
		}
		updateSetting(
			settings
		);
  }, [gpsValues, webServiceValues, settings, updateSetting]);

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="position-relative mx-n4 mt-n4">
						<div className="profile-wid-bg profile-setting-img">
							<img src={progileBg} className="profile-wid-img" alt="" />
						</div>
					</div>
					<Row>
						
						<Col xxl={12}>
							<Card className="mt-xxl-n5">
								<CardHeader>
									<Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
										role="tablist">
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === "1" })}
												onClick={() => {
													tabChange("1");
												}}>
												<i className="fas fa-home mr-2"></i>
												{t("System Settings")}
											</NavLink>
										</NavItem>
									</Nav>
								</CardHeader>
								<CardBody className="p-2">
									<TabContent activeTab={activeTab}>

										<TabPane tabId="1">
											<Row>

												<Col lg={6}>
													<div className="m-4">
														<Label htmlFor="gps_range" className="form-label">{t("GPS Delay Status Range")}</Label>
														<div
															style={{
																display: 'flex',
																justifyContent: 'center',
																flexWrap: 'wrap'
															}}
														>
															<Range
																values={gpsValues}
																step={STEP}
																min={MIN}
																max={MAX}
																onChange={(values) => setGpsValues(values)}
																renderTrack={({ props, children }) => (
																	<div
																		onMouseDown={props.onMouseDown}
																		onTouchStart={props.onTouchStart}
																		style={{
																			...props.style,
																			height: '36px',
																			display: 'flex',
																			width: '100%'
																		}}
																	>
																		<div
																			ref={props.ref}
																			style={{
																				height: '5px',
																				width: '100%',
																				borderRadius: '4px',
																				background: getTrackBackground({
																					values: gpsValues,
																					colors: COLORS,
																					min: MIN,
																					max: MAX,
																				}),
																				alignSelf: 'center'
																			}}
																		>
																			{children}
																		</div>
																	</div>
																)}
																renderThumb={({ props }) => (
																	<div
																		{...props}
																		style={{
																			...props.style,
																			height: '30px',
																			width: '30px',
																			borderRadius: '30px',
																			backgroundColor: '#FFF',
																			display: 'flex',
																			justifyContent: 'center',
																			alignItems: 'center',
																			boxShadow: '0px 2px 6px #AAA'
																		}}
																	/>
																)}
															/>
														</div>
														<div className="table-responsive mt-3">
															<table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
																<tbody className="border-0">
																	<tr>
																		<td>
																			<h4 className="text-truncate fs-14 fs-medium mb-0">
																				<i className="ri-stop-fill align-middle fs-18 text-success me-2"></i>{t("GPS Updated")}
																			</h4>
																		</td>
																		<td className="text-end">
																			<p className="fs-22">
																				0 - {gpsValues[0].toFixed(1)}
																			</p>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<h4 className="text-truncate fs-14 fs-medium mb-0">
																				<i className="ri-stop-fill align-middle fs-18 text-warning me-2"></i>{t("GPS Warning")}
																			</h4>
																		</td>
																		<td className="text-end">
																		<p className="fs-22">
																				{gpsValues[0].toFixed(1)} - {gpsValues[1].toFixed(1)}
																			</p>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<h4 className="text-truncate fs-14 fs-medium mb-0">
																				<i className="ri-stop-fill align-middle fs-18 text-danger me-2"></i>{t("GPS Outdated")}
																			</h4>
																		</td>
																		<td className="text-end">
																		<p className="fs-22">
																				{gpsValues[1].toFixed(1)}
																			</p>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</Col>

												{/*<Col lg={6}>
													<div className="m-4">
														<Label htmlFor="ws_range" className="form-label">{t("Web Services Delay Status Range")}</Label>
						
														<Range
															values={webServiceValues}
															step={STEP}
															min={MIN}
															max={MAX}
															onChange={(values) => setWebServiceValues(values)}
															renderTrack={({ props, children }) => (
																<div
																	onMouseDown={props.onMouseDown}
																	onTouchStart={props.onTouchStart}
																	style={{
																		...props.style,
																		height: '36px',
																		display: 'flex',
																		width: '100%'
																	}}
																>
																	<div
																		ref={props.ref}
																		style={{
																			height: '5px',
																			width: '100%',
																			borderRadius: '4px',
																			background: getTrackBackground({
																				values: webServiceValues,
																				colors: COLORS,
																				min: MIN,
																				max: MAX,
																			}),
																			alignSelf: 'center'
																		}}
																	>
																		{children}
																	</div>
																</div>
															)}
															renderThumb={({ props }) => (
																<div
																	{...props}
																	style={{
																		...props.style,
																		height: '30px',
																		width: '30px',
																		borderRadius: '30px',
																		backgroundColor: '#FFF',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		boxShadow: '0px 2px 6px #AAA'
																	}}
																/>
															)}
														/>

														<div className="table-responsive mt-3">
															<table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
																<tbody className="border-0">
																	<tr>
																		<td>
																			<h4 className="text-truncate fs-14 fs-medium mb-0">
																				<i className="ri-stop-fill align-middle fs-18 text-success me-2"></i>{t("Web Service Updated")}
																			</h4>
																		</td>
																		<td className="text-end">
																			<p className="fs-22">
																				0 - {webServiceValues[0].toFixed(1)}
																			</p>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<h4 className="text-truncate fs-14 fs-medium mb-0">
																				<i className="ri-stop-fill align-middle fs-18 text-warning me-2"></i>{t("Web Service Warning")}
																			</h4>
																		</td>
																		<td className="text-end">
																		<p className="fs-22">
																				{webServiceValues[0].toFixed(1)} - {webServiceValues[1].toFixed(1)}
																			</p>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<h4 className="text-truncate fs-14 fs-medium mb-0">
																				<i className="ri-stop-fill align-middle fs-18 text-danger me-2"></i>{t("Web Service Outdated")}
																			</h4>
																		</td>
																		<td className="text-end">
																		<p className="fs-22">
																				{webServiceValues[1].toFixed(1)}
																			</p>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														
													</div>
													
												</Col>*/}
												
												<Col lg={12}>
													<div className="hstack gap-2 justify-content-end">
														<button type="button" className="btn btn-light" >{t("Close")}</button>
														<button className="btn btn-success" id="addNewUser" onClick={() => {
															doUpdateSettings();
														}}>{t("Save")}</button>
													</div>
												</Col>
											</Row>
										</TabPane>

									</TabContent>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default SystemSettings;