import React, { Fragment, useState, useMemo, useCallback } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import DeviceStatus from "./DeviceStatus";
import GPSDeviceStatus from "./GPSDeviceStatus";
import ChangeCompanyModal from "./ChangeCompanyModal"
import { toast } from 'react-toastify';
import { useTranslation, initReactI18next } from "react-i18next";
import { hasGroup } from "../../api/session_helper";

import 'react-toastify/dist/ReactToastify.css';

const DeviceTable = ({
  data,
  isLoading,
  isSuccess,
  error,
}) => {

  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      /*{
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input 
            type="checkbox" 
            className="orderCheckBox form-check-input" 
            checked={selectedCheckBoxDelete.includes(cellProps.row.original.id)} 
            value={cellProps.row.original.id} 
            onChange={(e) => { deleteCheckbox(e.target.value); }} />;
        },
        id: '#',
      },*/
      {
        Header: t("Plate"),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`${cell.row.original.id}`} className="fw-medium link-primary h5">{cell.value}</Link>;
        },
      },
      {
        Header: "IMEI",
        accessor: "imei",
        filterable: false,
        Cell: (cell) => (
          <div className="fs-14 mb-1">{cell.value}</div>
        )
      },
      {
        Header: "RUT",
        accessor: "rut",
        filterable: false,
        Cell: (cell) => (
          <div className="fs-14 mb-1">{cell.value}</div>
        )
      },
      {
        Header: t("Company"),
        filterable: false,
        Cell: (cell) => {
					if(hasGroup(['Company Manager'])){
						return <div className="fw-medium h5">{cell.row.original.company.name}</div>
					}
					if(hasGroup(['Platform Manager'])){
						if(cell.row.original.company != null)
							return <>
								<Link to={`../companies/${cell.row.original.company.id}`} className="fw-medium link-primary h5">{cell.row.original.company.name}</Link>
								<Button 
									color="link" 
									onClick={() => {
										setSelectedId(cell.row.original.id);
										setModal(true);
									}} 
									className="" 
									type="button"
									id="password-addon">
									<i className="ri-pencil-fill align-middle"></i>
								</Button>
							</>;
						else
							return <Button 
								color="link" 
								onClick={() => {
									setSelectedId(cell.row.original.id);
									setModal(true);
								}} 
								className="" 
								type="button"
								id="password-addon">
								<i className="ri-pencil-fill align-middle"></i>
							</Button>;
					}
        },
      },
      {
        Header: t("Integrations count"),
        filterable: false,
        Cell: (cell) => (
          <div className="fs-14 mb-1">{cell.row.original.integrations.length}</div>
        )
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (order) => (
          <>
            {handleValidDate(order.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(order.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t('GPS Status'),
        Cell: (order) => {
          return <GPSDeviceStatus status={order.row.original.gps_status} status_text={order.row.original.gps_status_name} />;
        }
      },
      /*{
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              
              <li className="list-inline-item">
                <Link 
                  to={`${cellProps.row.original.id}`} 
                  className="fw-medium link-primary"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>                
              </li>
            </ul>
          );
        },
      },*/
    ],
    []
  );

  const handleSuccessAssign = (importObject) => {
		setModal(false);
    toast.success(t(`Company assigned successfully`), {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			bodyClassName: "fs-24",}
		);
	};

	const handleAssignError = () => {
		setModal(false);
    toast.error(t(`There was an error while assigning the company`), {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			bodyClassName: "fs-24",}
		);
	};

  return (
    <>
      <ChangeCompanyModal 
				deviceId={selectedId}
				show={modal}
				onClose={() => setModal(false)}
				onAssignSuccess={handleSuccessAssign}
				onAssignError={handleAssignError}
			/>
      <OrdersTableBase
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        manualPagination={true}
        customPageSize={25}
        divClass="table-responsive table-card mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </>
  );
};

export default DeviceTable;

