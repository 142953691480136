import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAsyncDebounce } from "react-table";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Form,
  ModalBody,
  Label,
  Input,
  Button,
  ModalHeader,
  DropdownItem, 
  DropdownMenu, 
  DropdownToggle, 
  Offcanvas, 
  OffcanvasBody, 
  UncontrolledDropdown, 
  FormFeedback, 
  Spinner
} from "reactstrap";
import Select from "react-select";
import Cookies from 'js-cookie';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import DeleteModal from "../../Components/Commons/DeleteModal";
import Pagination from "../../Components/Commons/Pagination";
import EmptyState from "../../Components/Commons/EmptyState";
import { hasGroup } from "../../api/session_helper";

import { 
  useGetUsersQuery,
  useCreateUserMutation, 
  useDeleteUserMutation,
  useGetUserGroupsQuery,
  useGetAllCompaniesQuery
} from '../../api/api'


const Users = () => {
  
  const { t } = useTranslation();

  let navigate = useNavigate();

  const { 
    data: companies,
    error: companiesError,
    isFetching: companiesLoading,
    isSuccess: companiesSuccess
  } = useGetAllCompaniesQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [userSearch, setUserSearch] = useState(null);
  const [userGroups, setUserGroups] = useState(null);  
  const [userCompany, setUserCompany] = useState(null);  

  const [user, setUser] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  //Modal  
  const [newUser, setNewUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [createAdmin, seCreateAdmin] = useState(false);

  const { 
    data: usersPages,
    error,
    isFetching: isUsersLoading,
    isSuccess: isUsersSuccess
  } = useGetUsersQuery({ 
    page: currentPage,
    filter: userSearch,
    groups: userGroups,
    company: (hasGroup(['Company Manager']) && Cookies.get('company')) || userCompany
  }, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      validation.resetForm();
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleCreateUser = () => {
    seCreateAdmin(false);
    setNewUser(null);
    setModal(!modal);
    setIsEdit(false);
    toggle();
  };

  const handleCreateAdmin = () => {
    seCreateAdmin(true);
    setNewUser(null);
    setModal(!modal);
    setIsEdit(false);
    toggle();
  };

  const handleUserEdit = useCallback((arg) => {
    const user = arg;
    navigate(`${user.id}/edit`)
  }, [toggle]);

  // delete
  const onClickData = (user) => {
    setUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (user) {
      deleteUser(user.id);
      setDeleteModal(false);
    }
  };

  const [
    deleteUser, 
    { error: deletionErrors, 
      isLoading: isDeletionLoading,
      isSuccess: isDeletionSuccess
    }] = useDeleteUserMutation();

  useEffect(() => {
		if (isDeletionSuccess) {
			toast.success(t(`User successfully deleted`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
		if (deletionErrors) {
			toast.error(t(`There was an error while deleting the user`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	}, [isDeletionSuccess, deletionErrors]);
  
  const [
    createUser, 
    { error: errors, 
      isLoading: isCreationLoading,
      isSuccess: isCreationSuccess
    }] = useCreateUserMutation();

    useEffect(() => {
      if (isCreationSuccess) {
        setModal(false);
        validation.resetForm();
        toast.success(t(`User successfully created`), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          bodyClassName: "fs-24",}
        );
      }
      if (errors) {
        toast.error(t(`There was an error while creating the user`), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          bodyClassName: "fs-24",}
        );
      }
    }, [isCreationSuccess, errors]);

  const { 
		data: groups,
		error: groupsError,
		isFetching: groupsLoading,
		isSuccess: groupsSuccess
	} = useGetUserGroupsQuery(
		{
			refetchOnMountOrArgChange: true,
		}
	);

  const onSearchChange = useAsyncDebounce((value) => {
    setUserSearch(value);
  }, 200);

  const handleGroupSelect = (selection) => {
    setCurrentPage(1);
    setUserGroups(selection ? [selection.value] : null);
  };

  const handleCompanySelect = (selection) => {
    setCurrentPage(1);
    setUserCompany(selection ? selection.value : null);
  };

  useEffect(() => {
    const list = document.querySelectorAll(".team-list");
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
      buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
      if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
        document.getElementById("list-view-button").classList.add("active");
        document.getElementById("grid-view-button").classList.remove("active");
        list.forEach(function (el) {
          el.classList.add("list-view-filter");
          el.classList.remove("grid-view-filter");
        });

      } else {
        document.getElementById("grid-view-button").classList.add("active");
        document.getElementById("list-view-button").classList.remove("active");
        list.forEach(function (el) {
          el.classList.remove("list-view-filter");
          el.classList.add("grid-view-filter");
        });
      }
    }
  }, []);


  //OffCanvas  
  const [isOpen, setIsOpen] = useState(false);
  const [sideBar, setSideBar] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      first_name: (newUser && newUser.first_name) || '',
      last_name: (newUser && newUser.last_name) || '',
      email: (newUser && newUser.email) || '',
      company_id: (hasGroup(['Company Manager']) && Cookies.get('company') || (newUser && newUser.company_id)),
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required(t("Please Enter First Name")),
      last_name: Yup.string().required(t("Please Enter Last Name")),
      email: Yup.string().required(t("Please Enter Email")),
    }),
    onSubmit: (values) => {      
      const newUser = {
        email: values["email"],
        first_name: values["first_name"],
        last_name: values["last_name"]
      };
      if(createAdmin){
        newUser['group'] = 'Platform Manager';
      } else {
        if(values["company_id"]){
          const company = {
            id: values["company_id"]
          };
          newUser['company'] = company;
          newUser['group'] = 'Company Manager';
        }
      }
      createUser(newUser);
    },
  });

  document.title = t("WebService Data Replication");

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteUser()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Users List")} pageTitle={t("Users")} />
          <Card>
            <CardBody>
              <Row className="g-2">
              <Col sm={3}>
                <div className="search-box">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder={t("Search")}
                    onChange={(e) => onSearchChange(e.target.value)} 
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </Col>

              {groups && (
                <Col md={2}>
                  <Select
                    placeholder={t("User Type")}
                    classNamePrefix="form-select"
                    isLoading={groupsLoading}
                    isClearable={true}
                    name="types"
                    onChange={handleGroupSelect}
                    options={groups.map((group, key) => {
                      return {
                        ...group,
                        value: group.name,
                        label: group.name,
                      };
                    })}
                  />
                </Col>
              )}

              {hasGroup(['Platform Manager']) && companies && (
                <Col md={2}>
                  <Select
                    placeholder={t("Company")}
                    classNamePrefix="form-select"
                    isLoading={companiesLoading}
                    isClearable={true}
                    name="types"
                    onChange={handleCompanySelect}
                    options={companies.map((company, key) => {
                      return {
                        ...company,
                        value: company.id,
                        label: company.name,
                      };
                    })}
                  />
                </Col>
              )}

              <Col className="col-sm-auto ms-auto">
                <div className="list-grid-nav hstack gap-1">
                  <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i className="ri-grid-fill"></i></Button>
                  <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"><i className="ri-list-unordered"></i></Button>
                  <Button color="success" onClick={() => handleCreateUser()}>
                    <i className="ri-add-fill me-1 align-bottom"></i> {t("Create User")}
                  </Button>
                  {hasGroup(['Platform Manager']) && (
                    <Button color="primary" onClick={() => handleCreateAdmin()}>
                      <i className="ri-add-fill me-1 align-bottom"></i> {t("Create Admin")}
                    </Button>
                  )}
                </div>
                  
              </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col lg={12}>
              <div id="teamlist">
                <Row className="team-list grid-view-filter">
                  {(usersPages != null ? usersPages.results : []).map((item, key) => (
                    <Col key={key}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img src={item.backgroundImg} alt="" className="img-fluid" />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col className="team-settings">
                              <Row>
                                <Col>
                                  <div className="flex-shrink-0 me-2">
                                    {/*<button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn" onClick={(e) => { setIsOpen(!isOpen); setSideBar(e.target); }}>
                                      <i className="ri-information-fill fs-14 text-muted"></i>
                                    </button>*/}
                                  </div>
                                </Col>
                                <UncontrolledDropdown direction='start' className="col text-end">
                                  <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                    <i className="ri-more-fill fs-17"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem className="dropdown-item edit-list" onClick={() => handleUserEdit(item)}>
                                      <i className="ri-pencil-line me-2 align-bottom text-muted"></i>{t("Edit")}
                                    </DropdownItem>
                                    <DropdownItem className="dropdown-item remove-list" onClick={() => onClickData(item)}>
                                      <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>{t("Remove")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Row>
                            </Col>
                            <Col lg={4} className="col">
                              <div className="team-profile-img">

                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                  <div className="avatar-title text-uppercase border rounded-circle bg-light text-primary fs-24">
                                    {item.first_name.charAt(0) + item.last_name.charAt(0)}
                                  </div>
                                </div>
                                <div className="team-content">
                                  <h5 className="fs-16 mb-1">{item.first_name} {item.last_name}</h5>
                                  <p className="text-muted mb-0">{item.email}</p>
                                  <p className="mb-1">{item.company ? item.company.name : '--'}</p>
                                </div>
                              </div>
                            </Col>
                            <Col lg={2} className="col">
                              <div className="text-end">
                                <Link to={`${item.id}`} className="btn btn-light view-btn">{t("View Profile")}</Link>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}

                  {!isUsersLoading && usersPages && usersPages.count == 0 && (
                    <EmptyState
                      title={t("No records found")}
                      description={t("Change the filters for better results")}
                    />
                  )}

                </Row>

                {usersPages && usersPages.count > 0 && (
                  <Pagination
                    totalRows={usersPages.count}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={25}
                  />
                )}

                <div className="modal fade" id="addmembers" tabIndex="-1" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <Modal isOpen={modal} toggle={toggle} centered>
                      <ModalHeader toggle={toggle}>{createAdmin ? t("Create Admin User") : t("Create Company User")}</ModalHeader>
                      <ModalBody>
                        <Form onSubmit={validation.handleSubmit}>
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="first_name" className="form-label">{t("Name")}</Label>
                                <Input type="text" className="form-control" id="first_name" placeholder={t("Enter first name")}
                                  name='first_name'
                                  validate={{
                                      required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name || ""}
                                  invalid={
                                    (validation.errors.first_name) || (errors && errors.first_name) ? true : false
                                  }
                                />
                                {validation.touched.first_name && validation.errors.first_name ? (
                                  <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                ) : null}
                                {errors && errors.first_name ? (<FormFeedback type="invalid">{errors.first_name}</FormFeedback>) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="last_name" className="form-label">{t("Last Name")}</Label>
                                <Input type="text" className="form-control" id="last_name" placeholder={t("Last Name")}
                                  name='last_name'
                                  validate={{
                                      required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.last_name || ""}
                                  invalid={
                                    (validation.errors.last_name) || (errors && errors.last_name)? true : false
                                  }
                                />
                                {validation.touched.last_name && validation.errors.last_name ? (
                                  <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                ) : null}
                                {errors && errors.last_name ? (<FormFeedback type="invalid">{errors.last_name}</FormFeedback>) : null}
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label htmlFor="email" className="form-label">{t("Email")}</Label>
                                <Input type="text" className="form-control" id="email" placeholder={t("Enter email")} name='email'
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    (validation.errors.email) || (errors && errors.email) ? true : false
                                  }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                                {errors && errors.non_field_errors ? (<FormFeedback type="invalid">{errors.non_field_errors}</FormFeedback>) : null}
                                {errors && errors.email ? (<FormFeedback type="invalid">{errors.email}</FormFeedback>) : null}
                              </div>
                            </Col>
                            {hasGroup(['Platform Manager']) && !createAdmin && (
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label htmlFor="group" className="form-label">{t("Company")}</Label>
                                  {companies && <Select
                                    classNamePrefix="form-select"
                                    isLoading={companiesLoading}
                                    isClearable={true}
                                    name="types"
                                    defaultValue={validation.values.company_id}
                                    onChange={(selection) => {validation.setFieldValue('company_id', selection ? selection.id : null)}}
                                    options={companies.map((company, key) => {
                                      return {
                                        ...company,
                                        value: company.id,
                                        label: company.name,
                                      };
                                    })}
                                  />}
                                  {validation.errors.company ? (
                                    <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                                  ) : null}
                                  {errors && errors.company ? (<FormFeedback type="invalid">{errors.company.id}</FormFeedback>) : null}
                                </div>
                              </Col>
                            )}
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => toggle()}>{t("Close")}</button>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  disabled={isDeletionLoading}
                                  id="addNewUser">
                                    {isCreationLoading ? <Spinner size="sm" className='me-2'> {t("Loading")}... </Spinner> : null}
                                    {!isEdit ? (createAdmin ? t("Create Admin User") : t("Create Company User")) : t("Save")}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>

                <Offcanvas
                    isOpen={isOpen}
                    direction="end"
                    toggle={() => setIsOpen(!isOpen)}
                    className="offcanvas-end border-0"
                    tabIndex="-1"
                    id="member-overview"
                >
                  <OffcanvasBody className="profile-offcanvas p-0">
                    <div className="team-cover">
                      {/*<img src={sideBar.backgroundImg || smallImage9} alt="" className="img-fluid" />*/}
                    </div>
                    <div className="p-3">
                      <div className="team-settings">
                        <Row>
                          <Col>
                            <button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn "> <i className="ri-star-fill fs-14"></i> </button>
                          </Col>
                          <UncontrolledDropdown direction='start' className="col text-end">
                            <DropdownToggle tag="a" id="dropdownMenuLink14" role="button">
                              <i className="ri-more-fill fs-17"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                              <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Row>
                      </div>
                    </div>
                    <div className="p-3 text-center">
                      {/*<img src={sideBar.userImage || avatar2} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />*/}
                      <div className="mt-3">
                        <h5 className="fs-15 profile-name"><Link to="#" className="link-primary">{sideBar.name || "Nancy Martino"}</Link></h5>
                        <p className="text-muted profile-designation">{sideBar.designation || "Team Leader & HR"}</p>
                      </div>
                      <div className="hstack gap-2 justify-content-center mt-4">
                        <div className="avatar-xs">
                          <Link to="#" className="avatar-title bg-soft-secondary text-secondary rounded fs-16">
                            <i className="ri-facebook-fill"></i>
                          </Link>
                        </div>
                        <div className="avatar-xs">
                          <Link to="#" className="avatar-title bg-soft-success text-success rounded fs-16">
                            <i className="ri-slack-fill"></i>
                          </Link>
                        </div>
                        <div className="avatar-xs">
                          <Link to="#" className="avatar-title bg-soft-info text-info rounded fs-16">
                            <i className="ri-linkedin-fill"></i>
                          </Link>
                        </div>
                        <div className="avatar-xs">
                          <Link to="#" className="avatar-title bg-soft-danger text-danger rounded fs-16">
                            <i className="ri-dribbble-fill"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Row className="g-0 text-center">
                      <Col xs={6}>
                        <div className="p-3 border border-dashed border-start-0">
                          <h5 className="mb-1 profile-project">{sideBar.projectCount || "124"}</h5>
                          <p className="text-muted mb-0">Projects</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="p-3 border border-dashed border-start-0">
                          <h5 className="mb-1 profile-task">{sideBar.taskCount || "81"}</h5>
                          <p className="text-muted mb-0">Tasks</p>
                        </div>
                      </Col>
                    </Row>
                    <div className="p-3">
                      <h5 className="fs-15 mb-3">Personal Details</h5>
                      <div className="mb-3">
                        <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Number</p>
                        <h6>+(256) 2451 8974</h6>
                      </div>
                      <div className="mb-3">
                        <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Email</p>
                        <h6>nancymartino@email.com</h6>
                      </div>
                      <div>
                        <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Location</p>
                        <h6 className="mb-0">Carson City - USA</h6>
                      </div>
                    </div>
                  </OffcanvasBody>
                  <div className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
                    <Link to="/pages-profile" className="btn btn-primary w-100"><i className="ri-user-3-fill align-bottom ms-1"></i> View Profile</Link>
                  </div>
                </Offcanvas>
            </div>
            <div className="py-4 mt-4 text-center" id="noresult" style={{ display: "none" }}>
              <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{ width: "72px", height: "72px" }}></lord-icon>
              <h5 className="mt-4">Sorry! No Result Found</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

export default Users;


