import React, { useEffect, useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import {
  Label,
  Modal,
	ModalBody,
	ModalHeader,
	Form,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Loader from "../../Components/Commons/Loader";

import { 
  useIntegrateDeviceToWebServiceMutation,
  useGetAllowedWebServicesByCompanyQuery
} from '../../api/api'

const CreateIntegrationModal = ({
  device,
  show, 
  onClose, 
  onOrderImportSuccess, 
  onOrderImportError
}) => {

  const { t } = useTranslation();

  const { 
    data: webServices,
    error: webServicesError,
    isFetching: webServicesLoading,
    isSuccess: webServicesSuccess
  } = useGetAllowedWebServicesByCompanyQuery(
    device && device.company && device.company.id,
    {
    refetchOnMountOrArgChange: true,
    skip: device === undefined,
  });

	const [integrateDevice, 
    { data: importObject, 
      error: importErrors, 
      isLoading: isImportLoading, 
      isSuccess: isImportSuccess
    }] = useIntegrateDeviceToWebServiceMutation();

  useEffect(() => {
    if (isImportSuccess) {
      onOrderImportSuccess(importObject);
    }
    if (importErrors) {
      onOrderImportError(importErrors);
    }

  }, [isImportSuccess, importErrors]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,
  
    initialValues: {
      web_service_id: null,
    },
    
    validationSchema: Yup.object({
      web_service_id: Yup.string().required(t("Please Enter company Id")),
    }),
  
    onSubmit: (values) => {
      const body = {
        web_service_id: values["web_service_id"]
      };
      integrateDevice({id: device.id, body: body});
      validation.resetForm();
    },
  });

  return (
    <Modal id="showModal" isOpen={show} toggle={onClose} centered>
      <ModalHeader className="bg-light p-3" >
        {t("Integrate WebService")}
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
        {isImportLoading && <Loader/>}
        {!isImportLoading && <ModalBody>
          <div>
            <div className="mb-3">
              <Label
                htmlFor="choices-publish-status-input"
                className="form-label"
              >
                {t("Select the WebService to Integrate")}
              </Label>

              {webServices && <Select
                placeholder={t("Select a WebService")}
                required
                classNamePrefix="form-select"
                isLoading={webServicesLoading}
                isClearable={true}
                name="types"
                onChange={(selection) => {validation.setFieldValue('web_service_id', selection ? selection.id : null)}}
                options={webServices.map((company, key) => {
                  return {
                    ...company,
                    value: company.id,
                    label: company.name,
                  };
                })}
              />}

              {validation.touched.companyId && validation.errors.companyId ? (
                <FormFeedback type="invalid">{validation.errors.companyId}</FormFeedback>
              ) : null}
            </div>
          </div>
        </ModalBody>}
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              {t("Close")}
            </button>

            <button type="submit" className="btn btn-success" disabled={isImportLoading}>
              {t("Create Integration")}
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateIntegrationModal;