import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";

/*
NO_DATA = -1
UPDATED = 1
WARNING = 2
OUTDATED = 3
*/

//Constants
const DeviceStatusColor = ({ status}) => {
	if (status === 1) {
		return getChartColorsArray('["--vz-success"]')[0];
	} else if (status === 2) {
		return getChartColorsArray('["--vz-warning"]')[0];
	} else if (status === 3) {
		return getChartColorsArray('["--vz-danger"]')[0];
	} else if (status === -1) {
		return getChartColorsArray('["--vz-dark"]')[0];
	}
};

export default DeviceStatusColor;