import React from 'react';



//Constants
const DeviceStatus = ({ status, status_text}) => {
	if (status === 1) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-info"}>{status_text}</div>;
	}
};

export default DeviceStatus;