import React from 'react';

/*
ERROR = 0
ENTERED = 1
PENDING = 2
READY = 3

*/

//Constants
const SendInputStatus = ({ status, status_text}) => {
	if (status === 0) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 3) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	}
};

export default SendInputStatus;