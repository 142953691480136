import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import { withTranslation } from 'react-i18next';
import Select from "react-select";
import { useTranslation, initReactI18next } from "react-i18next";

const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage, pageSizeChangeHandler }) => {

  const { t } = useTranslation();

  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  // const pagesArr = [...new Array(noOfPages)];

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(25);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the butons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => {
    if(!isNaN(pageNo.target.value)){
      setCurrentPage(Number(pageNo.target.value));
    }
  }

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    pageChangeHandler(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if(pageSizeChangeHandler){
      pageSizeChangeHandler(currentPageSize);
    }
  }, [currentPageSize]);

  const paginationOptions = [
    {value: 25, label: '25'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
  ];

  return (
    <Row className="d-flex justify-content-between p-2">
      {pageSizeChangeHandler && (
        <Col>
          <Row className="d-flex justify-content-start p-2">
            <Col className="col-md-auto d-none d-md-block pt-2">
              <strong>
                {t("Page size")}
              </strong>
            </Col>

            <Col className="col-md-auto">
              <Select
                classNamePrefix="form-select"
                isClearable={false}
                name="types"
                defaultValue={paginationOptions[0]}
                onChange={(e) => {
                  setCurrentPageSize(e ? e.value : null);
                }}
                options={paginationOptions}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col>
        <Row className="d-flex justify-content-end p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
                <Button
                color="primary"
                onClick={onPrevPage}
                disabled={!canGoBack}
                >
                {"<"}
                </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block pt-2">
              {t("Page")}{" "}
            <strong>
                {currentPage} {t("of")} {noOfPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={noOfPages}
              defaultValue={currentPage}
              onChange={onPageSelect}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button 
              color="primary"
              onClick={onNextPage}
              disabled={!canGoNext}
              >
              {">"}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Pagination;