import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Row, TabContent, Table, TabPane, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap';
import SwiperCore, { Autoplay } from "swiper";
import { useTranslation, initReactI18next } from "react-i18next";
import { useGetUserByIdQuery } from '../../api/api'
import Loader from "../../Components/Commons/Loader";

//Images
import profileBg from '../../images/profile-bg.jpg';


const UserProfile = () => {

	let { id } = useParams();
	const { t } = useTranslation();

	const { 
		data: user,
		error,
		isFetching: isUserLoading,
		isSuccess: isUserSuccess
	} = useGetUserByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	SwiperCore.use([Autoplay]);

	const [activeTab, setActiveTab] = useState('1');
	const [activityTab, setActivityTab] = useState('1');

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const toggleActivityTab = (tab) => {
		if (activityTab !== tab) {
			setActivityTab(tab);
		}
	};

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="profile-foreground position-relative mx-n4 mt-n4">
						<div className="profile-wid-bg">
							<img src={profileBg} alt="" className="profile-wid-img" />
						</div>
					</div>
					<div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
						<Row className="g-4">
							{isUserLoading && <Loader/>}
							{isUserSuccess && (
								<div className="col-auto">
									<div className="avatar-lg">
										<div className="avatar-title text-uppercase border rounded-circle bg-light text-primary fs-24">
											{user.first_name.charAt(0) + user.last_name.charAt(0)}
										</div>
									</div>
								</div>
							)}

							{isUserLoading && <Loader/>}
							{isUserSuccess && (
								<Col>
									<div className="p-2">
										<h3 className="text-white mb-1">{user.first_name} {user.last_name}</h3>
										<p className="text-white-75">{user.email}</p>
										<div className="hstack text-white-50 gap-1">
											<div><i
												className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>{user.company ? user.company.name : ''}
											</div>
										</div>
									</div>
								</Col>
							)}

						</Row>
					</div>

					<Row>
						<Col lg={12}>
							<div>
								<div className="d-flex">
									<div className="flex-shrink-0">
										{isUserLoading && <Loader/>}
										{isUserSuccess && (
											<Link to="edit" className="btn btn-success"><i
												className="ri-edit-box-line align-bottom"></i> {t("Edit Profile")}</Link>
										)}
									</div>
								</div>

								<div className="pt-4">
									<Row>
										<Col xxl={4}>
											<Card>
												{isUserLoading && <Loader/>}
												{isUserSuccess && (
													<CardBody>
														<h5 className="card-title mb-3">{t("Profile Information")}</h5>
														<div className="table-responsive">
															<Table className="table-borderless mb-0">
																<tbody>
																	<tr>
																		<th className="ps-0" scope="row">{t("Full Name")}</th>
																		<td className="text-muted">{user.first_name} {user.last_name}</td>
																	</tr>
																	<tr>
																		<th className="ps-0" scope="row">{t("E-mail")}</th>
																		<td className="text-muted">{user.email}</td>
																	</tr>
																	<tr>
																		<th className="ps-0" scope="row">{t("Creation Date")}</th>
																		<td className="text-muted">{user.created_at}</td>
																	</tr>
																</tbody>
															</Table>
														</div>
													</CardBody>
												)}
											</Card>

										</Col>
										{/*<Col xxl={8}>

											<Row>
												<Col lg={12}>
													<Card>
														<CardHeader className="align-items-center d-flex">
															<h4 className="card-title mb-0  me-2">Recent Activity</h4>
														</CardHeader>
														<CardBody>
															<div className="profile-timeline">
																<div>
																</div>
																<div className="accordion accordion-flush" id="todayExample">
																	<div className="accordion-item border-0">
																		<div className="accordion-header">
																			<button className="accordion-button p-2 shadow-none" type="button" id="headingOne" >
																				<div className="d-flex">
																					<div className="flex-shrink-0">
																						<img src={avatar2} alt="" className="avatar-xs rounded-circle" />
																					</div>
																					<div
																						className="flex-grow-1 ms-3">
																						<h6
																							className="fs-14 mb-1">
																							Jacqueline Steve
																						</h6>
																						<small className="text-muted">We has changed 2 attributes on 05:16PM</small>
																					</div>
																				</div>
																			</button>
																		</div>
																		<UncontrolledCollapse className="accordion-collapse" toggler="#headingOne" defaultOpen>
																			<div
																				className="accordion-body ms-2 ps-5">
																				In an awareness campaign, it
																				is vital for people to begin
																				put 2 and 2 together and
																				begin to recognize your
																				cause. Too much or too
																				little spacing, as in the
																				example below, can make
																				things unpleasant for the
																				reader. The goal is to make
																				your text as comfortable to
																				read as possible. A
																				wonderful serenity has taken
																				possession of my entire
																				soul, like these sweet
																				mornings of spring which I
																				enjoy with my whole heart.
																			</div>
																		</UncontrolledCollapse>
																	</div>
																	<div className="accordion-item border-0">
																		<div className="accordion-header" id="headingTwo">
																			<Link to="#" className="accordion-button p-2 shadow-none" id="collapseTwo">
																				<div className="d-flex">
																					<div
																						className="flex-shrink-0 avatar-xs">
																						<div
																							className="avatar-title bg-light text-success rounded-circle">
																							M
																						</div>
																					</div>
																					<div
																						className="flex-grow-1 ms-3">
																						<h6
																							className="fs-14 mb-1">
																							Megan Elmore
																						</h6>
																						<small
																							className="text-muted">Adding
																							a new event with
																							attachments -
																							04:45PM</small>
																					</div>
																				</div>
																			</Link>
																		</div>
																		<UncontrolledCollapse toggler="collapseTwo" defaultOpen>
																			<div
																				className="accordion-body ms-2 ps-5">
																				<Row className="g-2">
																					<div className="col-auto">
																						<div
																							className="d-flex border border-dashed p-2 rounded position-relative">
																							<div
																								className="flex-shrink-0">
																								<i
																									className="ri-image-2-line fs-17 text-danger"></i>
																							</div>
																							<div
																								className="flex-grow-1 ms-2">
																								<h6><Link to="#"
																									className="stretched-link">Business
																									Template
																									-
																									UI/UX
																									design</Link>
																								</h6>
																								<small>685
																									KB</small>
																							</div>
																						</div>
																					</div>
																					<div className="col-auto">
																						<div
																							className="d-flex border border-dashed p-2 rounded position-relative">
																							<div
																								className="flex-shrink-0">
																								<i
																									className="ri-file-zip-line fs-17 text-info"></i>
																							</div>
																							<div
																								className="flex-grow-1 ms-2">
																								<h6><Link to="#"
																									className="stretched-link">Bank
																									Management
																									System
																									-
																									PSD</Link>
																								</h6>
																								<small>8.78
																									MB</small>
																							</div>
																						</div>
																					</div>
																				</Row>
																			</div>
																		</UncontrolledCollapse>
																	</div>
																	<div className="accordion-item border-0">
																		<div className="accordion-header"
																			id="headingThree">
																			<Link to="#" className="accordion-button p-2 shadow-none">
																				<div className="d-flex">
																					<div
																						className="flex-shrink-0">
																						<img src={avatar5}
																							alt=""
																							className="avatar-xs rounded-circle" />
																					</div>
																					<div
																						className="flex-grow-1 ms-3">
																						<h6
																							className="fs-14 mb-1">
																							New ticket
																							received</h6>
																						<small
																							className="text-muted mb-2">User
																							<span
																								className="text-secondary">Erica245</span>
																							submitted a
																							ticket -
																							02:33PM</small>
																					</div>
																				</div>
																			</Link>
																		</div>
																	</div>
																	<div className="accordion-item border-0">
																		<div className="accordion-header"
																			id="headingFour">
																			<Link to="#" className="accordion-button p-2 shadow-none" id="collapseFour" >
																				<div className="d-flex">
																					<div
																						className="flex-shrink-0 avatar-xs">
																						<div
																							className="avatar-title bg-light text-muted rounded-circle">
																							<i
																								className="ri-user-3-fill"></i>
																						</div>
																					</div>
																					<div
																						className="flex-grow-1 ms-3">
																						<h6
																							className="fs-14 mb-1">
																							Nancy Martino
																						</h6>
																						<small
																							className="text-muted">Commented
																							on
																							12:57PM</small>
																					</div>
																				</div>
																			</Link>
																		</div>
																		<UncontrolledCollapse toggler="collapseFour" defaultOpen>
																			<div
																				className="accordion-body ms-2 ps-5">
																				" A wonderful serenity has
																				taken possession of my
																				entire soul, like these
																				sweet mornings of spring
																				which I enjoy with my whole
																				heart. Each design is a new,
																				unique piece of art birthed
																				into this world, and while
																				you have the opportunity to
																				be creative and make your
																				own style choices. "
																			</div>
																		</UncontrolledCollapse>
																	</div>
																	<div className="accordion-item border-0">
																		<div className="accordion-header"
																			id="headingFive">
																			<Link to="#" className="accordion-button p-2 shadow-none" id="collapseFive" >
																				<div className="d-flex">
																					<div
																						className="flex-shrink-0">
																						<img src={avatar7}
																							alt=""
																							className="avatar-xs rounded-circle" />
																					</div>
																					<div
																						className="flex-grow-1 ms-3">
																						<h6
																							className="fs-14 mb-1">
																							Lewis Arnold
																						</h6>
																						<small
																							className="text-muted">Create
																							new project
																							buildng product
																							-
																							10:05AM</small>
																					</div>
																				</div>
																			</Link>
																		</div>
																		<UncontrolledCollapse toggler="collapseFive" defaultOpen>
																			<div
																				className="accordion-body ms-2 ps-5">
																				<p className="text-muted mb-2">
																					Every team project can
																					have a velzon. Use the
																					velzon to share
																					information with your
																					team to understand and
																					contribute to your
																					project.</p>
																				<div className="avatar-group">
																					<Link to="#"
																						className="avatar-group-item"
																						data-bs-toggle="tooltip"
																						data-bs-trigger="hover"
																						data-bs-placement="top"
																						title=""
																						data-bs-original-title="Christi">
																						<img src={avatar4}
																							alt=""
																							className="rounded-circle avatar-xs" />
																					</Link>
																					<Link to="#"
																						className="avatar-group-item"
																						data-bs-toggle="tooltip"
																						data-bs-trigger="hover"
																						data-bs-placement="top"
																						title=""
																						data-bs-original-title="Frank Hook">
																						<img src={avatar3}
																							alt=""
																							className="rounded-circle avatar-xs" />
																					</Link>
																					<Link to="#"
																						className="avatar-group-item"
																						data-bs-toggle="tooltip"
																						data-bs-trigger="hover"
																						data-bs-placement="top"
																						title=""
																						data-bs-original-title=" Ruby">
																						<div
																							className="avatar-xs">
																							<div
																								className="avatar-title rounded-circle bg-light text-primary">
																								R
																							</div>
																						</div>
																					</Link>
																					<Link to="#"
																						className="avatar-group-item"
																						data-bs-toggle="tooltip"
																						data-bs-trigger="hover"
																						data-bs-placement="top"
																						title=""
																						data-bs-original-title="more">
																						<div
																							className="avatar-xs">
																							<div
																								className="avatar-title rounded-circle">
																								2+
																							</div>
																						</div>
																					</Link>
																				</div>
																			</div>
																		</UncontrolledCollapse>
																	</div>
																</div>

															</div>
														</CardBody>
													</Card>
												</Col>
											</Row>

										</Col>*/}
									</Row>

								</div>
							</div>
						</Col>
					</Row>

				</Container>
			</div>
		</React.Fragment>
	);
};

export default UserProfile;