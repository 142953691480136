//LOGIN
export const POST_AUTH = "/auth/";

// Orders
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/orders";
export const UPDATE_ORDER = "/orders";
export const DELETE_ORDER = "/orders";
export const IMPORT_ORDERS = "/OrderSets/import_data/";

// Profile
export const POST_PASSWORD_FORGET = "/orders";
export const POST_EDIT_PROFILE = "/user";
export const POST_REGISTER = "/user";