import React, { useEffect, useState, useCallback } from "react";
import { toast } from 'react-toastify';
import {
	Card,
	CardBody,
	CardHeader,
	Badge
} from "reactstrap";
import { useParams } from "react-router-dom";
import AllowedWebServiceTable from "./AllowedWebServiceTable";
import ErrorModal from "../../Components/Commons/ErrorModal";
import { useTranslation, initReactI18next } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetCompanyByIdQuery, 
	useGetWebServicesByCompanyQuery,
	useAllowWebServiceMutation,
	useDenyWebServiceMutation
} from '../../api/api'


const AllowedWebServices = () => {

	let { id } = useParams();

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const [webServices, setWebServices] = useState(null);
	const { t } = useTranslation();

	const { 
		data: company,
		error,
		isFetching: isCompanyLoading,
		isSuccess: isCompanySuccess
	} = useGetCompanyByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const { 
		data: webServicesPages,
		error: webServicesError,
		isFetching: iswebServiceLoading,
		isSuccess: isWebServiceSuccess
	} = useGetWebServicesByCompanyQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if (webServicesPages) {
			setWebServices(webServicesPages);
			setIsOpen(false);
		}
	}, [webServicesPages]);

	const [allowWebService, 
		{ data: allowedWebService, 
			error: allowedErrors, 
			isLoading: isAllowedLoading, 
			isSuccess: isAllowedSuccess
		}] = useAllowWebServiceMutation();


	useEffect(() => {
		if (isAllowedSuccess) {
			toast.success(t(`Integration allowed successfully`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
		if (allowedErrors) {
			toast.error(t(`There was an error while allowing the integration`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	}, [isAllowedSuccess, allowedErrors]);
	
	const [denyWebService, 
		{ data: deniedWebService, 
			error: deniedErrors, 
			isLoading: isDeniedLoading, 
			isSuccess: isDeniedSuccess
		}] = useDenyWebServiceMutation();

	useEffect(() => {
		if (isDeniedSuccess) {
			toast.success(t(`Integration denied successfully`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
		if (deniedErrors) {
			toast.error(t(`There was an error while denying the integration`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	}, [isDeniedSuccess, deniedErrors]);

	const allowTriggered = useCallback((webservice, enabled) => {
		if (webServices) {
			let newWebServices = webServices.filter(webService => webService.id !== webservice.id);
			let newWebService = {...webservice, is_current_company_active: enabled};
			newWebServices = [
				...newWebServices,
				newWebService
			]
			setWebServices(newWebServices);

			if(enabled){
				allowWebService({id: id, body: {web_service_id: webservice.id}});
			} else {
				denyWebService({id: id, body: {web_service_id: webservice.id}});
			}
		}
	}, [webServices]);

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<ErrorModal
				show={errorModal}
				onCloseClick={() => setErrorModal(false)}
				title={errorTitle}
				description={errorDescription}
			/>
			<Card id="orderList">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Allowed WebServices")} <Badge color="primary" className="ms-1">{company != null ? company.allowed_web_services.length : '...'}</Badge></h4>
				</CardHeader>
				<CardBody>
					<AllowedWebServiceTable
						data={webServices != null ? webServices : []}
						isLoading={iswebServiceLoading}
						isSuccess={isWebServiceSuccess}
						error={error}
						allowTriggered={allowTriggered}
						customPageSize={25}
						divClass="table-responsive table-card mb-1"
						tableClass="align-middle table-nowrap"
						theadClass="table-light text-muted"
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default AllowedWebServices;