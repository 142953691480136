import React, { useState, useEffect, useCallback } from 'react';
import { 
	Card,
	CardHeader,
	CardBody,
	Table,
	ModalHeader,
	Modal,
	ModalBody,
	Form,
	Row,
	Col,
	Label,
	Input,
	FormFeedback
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';

import Loader from "../../Components/Commons/Loader";
import WebServiceStatus from "./WebServiceStatus";

import { 
	useGetWebServiceByIdQuery,
	useUpdateWebServiceByIdMutation
 } from '../../api/api'

const WebServiceSummary = () => {

	const { t } = useTranslation();
	let { id } = useParams();

	const [modal, setModal] = useState(false);

	const { 
		data: webService,
		error,
		isFetching: isWebServiceLoading,
		isSuccess: isWebServiceSuccess
	} = useGetWebServiceByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const [updateWebService, { 
		error: updateErrors, 
		isSuccess: isEditionSuccess
	}] = useUpdateWebServiceByIdMutation();

	useEffect(() => {
		if (isEditionSuccess) {
			toggle();
			toast.success(t(`WebService edited successfully`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}

		if (updateErrors) {
			toast.error(t(`There was an error while editing the WebService`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	}, [isEditionSuccess, updateErrors]);
  
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			name: webService ? webService.name : '',
			url: webService ? webService.url : '',
			password: webService ? webService.password : '',
			token: webService ? webService.token : '',
		},
		onSubmit: (values) => {
			if (webService) {
				const body = {
					name: values["name"],
					url: values["url"],
					password: values["password"],
					token: values["token"],
				};
				updateWebService({id: id, body: body});
			}
		},
	});

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
			validation.resetForm();
		} else {
			setModal(true);
		}
	}, [modal]);

	return (
		<React.Fragment>
			<Card className="mb-3 card-height-100">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Web Service Details")}</h4>
					<div className="flex-shrink-0">
						<button
							type="button"
							className="btn btn-success btn-sm"
							id="create-btn"
							onClick={() => { setModal(true); }}>
							<i className="ri-pencil-line align-bottom me-1"></i> {t("Edit")}
						</button>
					</div>
				</CardHeader>
				<CardBody>
					{isWebServiceLoading && <Loader/>}
					{isWebServiceSuccess && (
						<div className="table-responsive table-card">
							<Table className="table mb-0">
								<tbody>
									<tr>
										<td className="fw-medium text-muted">{t("Name")}</td>
										<td className="overview-industryType">{webService.name}</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Status")}</td>
										<td className="overview-industryType">
											<WebServiceStatus status={webService.status} status_text={webService.status_text} />
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("URL")}</td>
										<td className="overview-industryType">
											{webService.url}
										</td>
									</tr>
								</tbody>
							
							</Table>
						</div>
					)}
				</CardBody>
			</Card>
			<div className="modal fade" id="addcompany" tabIndex="-1" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<Modal isOpen={modal} toggle={toggle} centered>
						<ModalHeader toggle={toggle}>{t("Edit WebService")}</ModalHeader>
						<ModalBody>
							<Form onSubmit={validation.handleSubmit}>
								<Row>
									<Col lg={12}>
										<div className="mb-3">
											<Label htmlFor="name" className="form-label">{t("Name")}</Label>
											<Input type="text" className="form-control" id="name" placeholder={t("Name")}
												name='name'
												validate={{
													required: { value: true },
												}}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.name || ""}
												invalid={(validation.errors.name) || (updateErrors && updateErrors.name) ? true : false}
											/>
											{validation.errors.name ? (
												<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{updateErrors && updateErrors.name ? (<div>{updateErrors.name}</div>) : null}</FormFeedback>
										</div>
									</Col>
									<Col lg={12}>
										<div className="mb-3">
											<Label htmlFor="url" className="form-label">{t("URL")}</Label>
											<Input type="text" className="form-control" id="url" placeholder={t("URL")}
												name='url'
												validate={{
													required: { value: true },
												}}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.url || ""}
												invalid={(validation.errors.url) || (updateErrors && updateErrors.url) ? true : false}
											/>
											{validation.errors.url ? (
												<FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{updateErrors && updateErrors.url ? (<div>{updateErrors.url}</div>) : null}</FormFeedback>
										</div>
									</Col>
									<Col lg={12}>
										<div className="mb-3">
											<Label htmlFor="token" className="form-label">{t("Token")}</Label>
											<Input type="text" className="form-control" id="token" placeholder={t("Token")} name='token'
												validate={{
													required: { value: true },
												}}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.token || ""}
												invalid={(validation.errors.token) || (updateErrors && updateErrors.token) ? true : false}
											/>
											{validation.errors.token ? (
												<FormFeedback type="invalid">{validation.errors.token}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{updateErrors && updateErrors.token ? (<div>{updateErrors.token}</div>) : null}</FormFeedback>
										</div>
									</Col>
									<Col lg={12}>
										<div className="mb-3">
											<Label htmlFor="password" className="form-label">{t("Password")}</Label>
											<Input type="text" className="form-control" id="password" placeholder={t("Password")} name='password'
												validate={{
													required: { value: true },
												}}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.password || ""}
												invalid={(validation.errors.password) || (updateErrors && updateErrors.password) ? true : false}
											/>
											{validation.errors.password ? (
												<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{updateErrors && updateErrors.password ? (<div>{updateErrors.password}</div>) : null}</FormFeedback>
										</div>
									</Col>
									<Col lg={12}>
										<div className="hstack gap-2 justify-content-end">
											<button type="button" className="btn btn-light" onClick={() => toggle()}>Close</button>
											<button type="submit" className="btn btn-success" id="addNewMember">{t("Save")}</button>
										</div>
									</Col>
								</Row>
							</Form>
						</ModalBody>
					</Modal>
				</div>
			</div>
		</React.Fragment>
	);
};

export default WebServiceSummary;