import React, { useEffect, useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import {
  Label,
  Modal,
	ModalBody,
	ModalHeader,
	Form
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Loader from "../../Components/Commons/Loader";

import { 
  useAssignDeviceToCompanyMutation,
  useGetAllCompaniesQuery
} from '../../api/api'

const ChangeCompanyModal = ({
  deviceId,
  show, 
  onClose,
  onAssignSuccess, 
  onAssignError
}) => {

  const { t } = useTranslation();
  
  const { 
    data: companies,
    error: companiesError,
    isFetching: companiesLoading,
    isSuccess: companiesSuccess
  } = useGetAllCompaniesQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });

	const [assignDevice, 
  { data: assignObject, 
    error: assignErrors, 
    isLoading: isAssignLoading, 
    isSuccess: isAssigntSuccess
  }] = useAssignDeviceToCompanyMutation();

  useEffect(() => {
    if (isAssigntSuccess) {
      onAssignSuccess();
    }
    if (assignErrors) {
      onAssignError();
    }

  }, [isAssigntSuccess, assignErrors]);

  const validation = useFormik({
  
    initialValues: {
      company_id: null,
    },
    
    validationSchema: Yup.object({
      company_id: Yup.string().required(t("Please Enter Company")),
    }),
  
    onSubmit: (values) => {
      const editCompany = {
        company_id: values["company_id"]
      };
      assignDevice({id:deviceId, body:editCompany});
      validation.resetForm();
    },
  });

  return (
    <Modal id="showModal" isOpen={show} toggle={onClose} centered>
      <ModalHeader className="bg-light p-3" >
        {t("Change assigned company")}
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
        {isAssignLoading && <Loader/>}
        {!isAssignLoading && <ModalBody>
          <div>
            <div className="mb-3">
              <Label
                htmlFor="choices-publish-status-input"
                className="form-label"
              >
                {t("Select the Company to Assign")}
              </Label>

              {companies && <Select
                required
                classNamePrefix="form-select"
                isLoading={companiesLoading}
                isClearable={true}
                name="types"
                onChange={(selection) => {validation.setFieldValue('company_id', selection ? selection.id : null)}}
                options={companies.map((company, key) => {
                  return {
                    ...company,
                    value: company.id,
                    label: company.name,
                  };
                })}
              />}

              {validation.touched.companyId && validation.errors.companyId ? (
                <FormFeedback type="invalid">{validation.errors.companyId}</FormFeedback>
              ) : null}
            </div>
          </div>
        </ModalBody>}
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              {t("Close")}
            </button>

            <button type="submit" className="btn btn-success" disabled={isAssignLoading}>
              {t("Assign Company")}
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeCompanyModal;