import React, { useMemo, useCallback } from "react";
import { Button } from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import SendInputStatus from "./SendInputStatus";
import { useTranslation } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

const SendInputsTable = ({
	data,
	isLoading,
	isSuccess,
	error,
	onDetailsPressed,
	onDownloadPressed
}) => {

	const { t } = useTranslation();

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const handleValidTimeWithSeconds = (time) => {
		const updateTime = moment(time).format('HH:mm:ss.SSSSSS');
		return updateTime;
	};

	const detailsPressed = useCallback((integration) => {
		onDetailsPressed(integration);
	});

	const columns = useMemo(
		() => [
			{
				Header: "WebService",
				filterable: false,
				Cell: (cell) => {
					return <Link to={`../web-services/${cell.row.original.web_service.id}`} className="fw-medium link-primary h5">{cell.row.original.web_service.name}</Link>;
				},
			},
			{
				Header: t("Created at"),
				accessor: "created_at",
				Cell: (integration) => {
					if(integration.row.original.created_at)
						return <>
							{handleValidDate(integration.row.original.created_at)},
							<small className="text-muted"> {handleValidTime(integration.row.original.created_at)}</small>
						</>
					else
						return <></>
				},
			},
			{
				Header: t("Start sending at"),
				accessor: "solution_started_at",
				Cell: (integration) => {
					if(integration.row.original.solution_started_at)
						return <>
							{handleValidDate(integration.row.original.solution_started_at)},
							<small className="text-muted"> {handleValidTimeWithSeconds(integration.row.original.solution_started_at)}</small>
						</>
					else
						return <></>
				},
			},
			{
				Header: t("Finish sending at"),
				accessor: "solution_finished_at",
				Cell: (integration) => {
					if(integration.row.original.solution_finished_at)
						return <>
							{handleValidDate(integration.row.original.solution_finished_at)},
							<small className="text-muted"> {handleValidTimeWithSeconds(integration.row.original.solution_finished_at)}</small>
						</>
					else
						return <></>
				},
			},
			{
				Header: t('Status'),
				accessor: 'status',
				Cell: (integration) => {
					return <SendInputStatus status={integration.row.original.status} status_text={integration.row.original.status_text} />;
				}
			},
			{
				Header: t("Payload"),
				Cell: (cellProps) => {
					return (
					  <ul className="list-inline hstack gap-2 mb-0">
							<Button 
								color="link" 
								onClick={() => {
									const integrationData = cellProps.row.original;
									detailsPressed(integrationData);
								}} 
								className="" 
								type="button" 
								id="password-addon">
									<i className="ri-eye-fill align-middle"></i>
							</Button>
							<Button 
								color="link" 
								onClick={() => {
									const integrationData = cellProps.row.original;
									onDownloadPressed(integrationData);
								}} 
								className="" 
								type="button" 
								id="password-addon">
									<i className="ri-download-fill align-middle"></i>
							</Button>
					  </ul>
					);
				},
			  },
		],
		[]
	);

	return (
		<OrdersTableBase
			columns={columns}
			data={data}
			isLoading={isLoading}
			isSuccess={isSuccess}
			error={error}
			manualPagination={true}
			customPageSize={25}
			divClass="table-responsive table-card mb-1"
			tableClass="align-middle table-nowrap"
			theadClass="table-light text-muted"
		/>
	);
};

export default SendInputsTable;

