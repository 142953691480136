import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Auth Method
export const postAuth = data => api.create(url.POST_AUTH, data);
export const postForgetPwd = data => api.create(url.POST_PASSWORD_FORGET, data);
export const postProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);
export const postRegister = data => api.create(url.POST_REGISTER, data);

// get Orders
//export const getOrders = () => api.get(url.GET_ORDERS);

export const getOrders = async () => await api.get(url.GET_ORDERS);
export const importOrders = async (formData) => await api.create(url.IMPORT_ORDERS, formData);
