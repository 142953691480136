import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table, Col } from "reactstrap";
import { useTranslation, initReactI18next } from "react-i18next";
import { Link, useParams } from 'react-router-dom';

import Loader from "../../Components/Commons/Loader";
import WebServiceStatusColor from "./WebServiceStatusColor";
import DeviceStatusColor from "../Devices/DeviceStatusColor";
import OrderStatusesChart from "./OrderStatusesChart";

import { useGetWebServiceByIdQuery } from '../../api/api'

import * as moment from "moment";

const WebServiceStatistics = () => {

	let { id } = useParams();
	const { t } = useTranslation();

	const { 
		data: orderSet,
		error,
		isFetching: isOrderSetLoading,
		isSuccess: isOrderSetSuccess
	} = useGetWebServiceByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const [statusAmounts, setStatusAmounts] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [colors, setColors] = useState([]);
	//const [statistics, setStatistics] = useState([]);

	const [orderSetStatusAmounts, setOrderSetStatusAmounts] = useState([]);
	const [orderSetStatuses, setOrderSetStatuses] = useState([]);
	const [orderSetColors, setOrderSetColors] = useState([]);

	useEffect(() => {
		if (orderSet) {
			let amounts = orderSet.integrations_statistics.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setStatusAmounts(amounts);

			let statuses = orderSet.integrations_statistics.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setStatuses(statuses);

			const colors = orderSet.integrations_statistics.filter((status) => status.count > 0).map((status) =>
				WebServiceStatusColor({status: status.value})
			);
			setColors(colors);

			/*
			const statistics = block.orders_statistics.filter((status) => status.count > 0).map((status) => {
				return {
					...status,
					text_color: OrderStatusTextColor({status: status.value}),
				};
			});
			setStatistics(statistics);*/

			let orderSetAmounts = orderSet.devices_statistics.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setOrderSetStatusAmounts(orderSetAmounts);

			let orderSetStatuses = orderSet.devices_statistics.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setOrderSetStatuses(orderSetStatuses);

			const orderSetColors = orderSet.devices_statistics.filter((status) => status.count > 0).map((status) =>
				DeviceStatusColor({status: status.value})
			);
			setOrderSetColors(orderSetColors);

		}
	}, [orderSet]);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	return (
		<React.Fragment>
			<Col xxl={6}>
				<Card id="statistics" className="card-height-100">
					<CardHeader className="align-items-center d-flex">
						<h4 className="card-title mb-0 flex-grow-1">{t("Devices")}</h4>
					</CardHeader>
					<CardBody>
						<div dir="ltr">
							{isOrderSetLoading && <Loader/>}
							{isOrderSetSuccess && (
								<>
									<OrderStatusesChart 
										series={orderSetStatusAmounts}
										labels={orderSetStatuses}
										colors={orderSetColors}
										itemName={'Devices'} />
									{/*statistics && <MyPortfolio statuses={statistics}/>*/}
								</>
							)}
						</div>
					</CardBody>
				</Card>
			</Col>

			<Col xxl={6}>
				<Card id="statistics" className="card-height-100">
					<CardHeader className="align-items-center d-flex">
						<h4 className="card-title mb-0 flex-grow-1">{t("Integrations")}</h4>
					</CardHeader>
					<CardBody>
						<div dir="ltr">
							{isOrderSetLoading && <Loader/>}
							{isOrderSetSuccess && (
								<>
									<OrderStatusesChart
										series={statusAmounts}
										labels={statuses}
										colors={colors}
										itemName={t('Integrations')} />
									{/*statistics && <MyPortfolio statuses={statistics}/>*/}
								</>
							)}
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default WebServiceStatistics;