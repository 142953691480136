import React from 'react';

/*
ENTERED = 0
DISABLED = -1
UPDATED = 1
WARNING = 2
OUTDATED = 3
*/

//Constants
const WebServiceStatus = ({ status, status_text}) => {
	if (status === -1) {
		return <div className={"badge bg-dark"}>{status_text}</div>;
	} else if (status === 0) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 3) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	}
};

export default WebServiceStatus;