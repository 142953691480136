import React from 'react';
import ReactApexChart from "react-apexcharts";


const OrderStatusesChart = ({ colors, series, labels, itemName }) => {
	const options = {
		labels: labels,
		chart: {
			type: "donut",
			height: '100'
		},
		plotOptions: {
			pie: {
				size: 100,
				offsetX: 0,
				offsetY: 0,
				donut: {
					size: "70%",
					labels: {
						show: true,
						name: {
							show: true,
							fontSize: '18px',
							offsetY: -5,
						},
						value: {
							show: true,
							fontSize: '20px',
							color: '#343a40',
							fontWeight: 500,
							offsetY: 5,
							formatter: function (val) {
								return val;
							}
						},
						total: {
							show: true,
							fontSize: '13px',
							label: `Total ${itemName}`,
							color: '#9599ad',
							fontWeight: 500,
							formatter: function (w) {
								return w.globals.seriesTotals.reduce(function (a, b) {
									return a + b;
								}, 0);
							}
						}
					}
				},
			},
		},
		dataLabels: {
			enabled: true,
		},
		legend: {
			show: true,
			position: 'right',
			markers: {
				width: 20,
				height: 6,
				radius: 2,
			},
			itemMargin: {
				horizontal: 1,
				vertical: 0
			},
		},
		stroke: {
			lineCap: "round",
			width: 2
		},
		yaxis: {
			labels: {
				formatter: function (value) {
					return `${value} ${itemName}`;
				}
			},
			tickAmount: 4,
			min: 0
		},
		colors: colors,
	};
	return (
		<React.Fragment>
			<ReactApexChart dir="ltr"
				options={options}
				series={series}
				type="donut"
				height='200px'
				className="apex-charts"
			/>
		</React.Fragment>
	);
};

export default OrderStatusesChart;