import React, { useState, useEffect, useCallback } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, FormFeedback } from 'reactstrap';
import classnames from "classnames";
import Select from "react-select";
import { useGetUserByIdQuery } from '../../api/api'
import Loader from "../../Components/Commons/Loader";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { 
	useUpdateUserMutation, 
	useUpdateUserPasswordMutation
} from '../../api/api'

//import images
import progileBg from '../../images/profile-bg.jpg';

const EditUser = () => {

	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const [activeTab, setActiveTab] = useState("1");

	const tabChange = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	let { id } = useParams();

	const { 
		data: user,
		error,
		isFetching: isUserLoading,
		isSuccess: isUserSuccess
	} = useGetUserByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const [
		updateUser, 
		{ 
			error: errors,
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSucess
		}] = useUpdateUserMutation();

	const [
		updateUserPassword, 
		{ 
			error: updateUserPasswordErrors,
			isLoading: updateUserPasswordLoading,
			isSuccess: updateUserPasswordSucess
		}] = useUpdateUserPasswordMutation();

	useEffect(() => {
		if (isUpdateSucess || updateUserPasswordSucess) {
			navigate(`../../users/${id}`);
		}
	}, [isUpdateSucess, updateUserPasswordSucess]);

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			first_name: user ? user.first_name : '',
			last_name: user ? user.last_name : '',
		},
		/*validationSchema: Yup.object({
			//email: Yup.string().required("Please Enter Email"),
			first_name: Yup.string().required("Please Enter First Name"),
			last_name: Yup.string().required("Please Enter Last Name"),
			group: Yup.string().required("Please Enter Group"),
		}),*/
		onSubmit: (values) => {
			const user = {
				//email: values["email"],
				first_name: values["first_name"],
				last_name: values["last_name"],
				group: values["group"]
			};
			updateUser({id: id, body: user});
		},
	});

	const passwordValidation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			new_password1:  '',
			new_password2: '',
		},
		
		validationSchema: Yup.object({
			new_password1: Yup.string().required(t("Please Enter a password")),
			new_password2: Yup.string().required(t("Please Enter a new password"))
		}),

		onSubmit: (values) => {
			const newDriver = {
				user_id: id,
				new_password1:  values["new_password1"],
				new_password2: values["new_password2"]
			};
			// create new driver
			updateUserPassword(newDriver);
			//passwordValidation.resetForm();
		},
	});
	
	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="position-relative mx-n4 mt-n4">
						<div className="profile-wid-bg profile-setting-img">
							<img src={progileBg} className="profile-wid-img" alt="" />
						</div>
					</div>
					<Row>
						<Col xxl={3}>
							<Card className="mt-n5">
								<CardBody className="p-4">
									{isUserLoading && <Loader/>}
									{isUserSuccess && (
										<div className="text-center">
											<div className="profile-user position-relative d-inline-block mx-auto mb-4">
												<div className="avatar-xl img-thumbnail rounded-circle flex-shrink-0 user-profile-image">
													<div className="avatar-title text-uppercase border rounded-circle bg-light text-primary fs-24">
														{user.first_name.charAt(0) + user.last_name.charAt(0)}
													</div>
												</div>
											</div>
											
											<h5 className="fs-16 mb-1">{user.first_name} {user.last_name}</h5>
											<p className="text-muted mb-0">{user.email}</p>
										</div>
									)}
								</CardBody>
							</Card>

						</Col>

						<Col xxl={9}>
							<Card className="mt-xxl-n5">
								<CardHeader>
									<Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
										role="tablist">
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === "1" })}
												onClick={() => {
													tabChange("1");
												}}>
												<i className="fas fa-home mr-2"></i>
												{t("Personal Details")}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink to="#"
												className={classnames({ active: activeTab === "2" })}
												onClick={() => {
													tabChange("2");
												}}
												type="button">
												<i className="far fa-user mr-2"></i>
												{t("Change Password")}
											</NavLink>
										</NavItem>
									</Nav>
								</CardHeader>
								<CardBody className="p-4">
									<TabContent activeTab={activeTab}>

										<TabPane tabId="1">
											<Form onSubmit={validation.handleSubmit}>
												<Row>
													<Col lg={6}>
														<div className="mb-3">
															<Label htmlFor="first_name" className="form-label">{t("First name")}</Label>
															<Input type="text" className="form-control" id="first_name" placeholder={t("Enter first name")}
																name='first_name'
																validate={{
																	required: { value: true },
																}}
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={validation.values.first_name || ""}
																invalid={
																	(validation.errors.first_name) || (errors && errors.first_name) ? true : false
																}
															/>
															{validation.touched.first_name && validation.errors.first_name ? (
																<FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
															) : null}
															{errors && errors.first_name ? (<FormFeedback type="invalid">{errors.first_name}</FormFeedback>) : null}
														</div>
													</Col>
													<Col lg={6}>
														<div className="mb-3">
															<Label htmlFor="last_name" className="form-label">{t("Last name")}</Label>
															<Input type="text" className="form-control" id="last_name" placeholder={t("Enter last name")}
																name='last_name'
																validate={{
																	required: { value: true },
																}}
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={validation.values.last_name || ""}
																invalid={
																	(validation.errors.last_name) || (errors && errors.last_name)? true : false
																}
															/>
															{validation.touched.last_name && validation.errors.last_name ? (
																<FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
															) : null}
															{errors && errors.last_name ? (<FormFeedback type="invalid">{errors.last_name}</FormFeedback>) : null}
														</div>
													</Col>
													{/*<Col lg={12}>
														<div className="mb-3">
															<Label htmlFor="email" className="form-label">{t("Email")}</Label>
															<Input type="text" className="form-control" id="email" placeholder={t("Enter email")} name='email'
																validate={{
																	required: { value: true },
																}}
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={validation.values.email || ""}
																invalid={
																	(validation.errors.email) || (errors && errors.email) ? true : false
																}
															/>
															{validation.touched.email && validation.errors.email ? (
																<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
															) : null}
															{errors && errors.email ? (<FormFeedback type="invalid">{errors.email}</FormFeedback>) : null}
														</div>
													</Col>*/}
													<Col lg={12}>
														<div className="hstack gap-2 justify-content-end">
															<button type="button" className="btn btn-light" >{t("Close")}</button>
															<button type="submit" className="btn btn-success" id="addNewUser">{t("Save")}</button>
														</div>
													</Col>
												</Row>
											</Form>
										</TabPane>

										<TabPane tabId="2">
											<Form onSubmit={passwordValidation.handleSubmit}>
												<Row className="g-2">
														
													<Col lg={4}>
														<div>
															<Label htmlFor="new_password1" className="form-label">{t("New Password*")}</Label>
															<Input
																type="password"
																className="form-control"
																id="new_password1"
																placeholder={t("Enter new password")}
																name='new_password1'
																validate={{
																	required: { value: true },
																}}
																onChange={passwordValidation.handleChange}
																onBlur={passwordValidation.handleBlur}
																value={passwordValidation.values.new_password1 || ""}
																invalid={
																	(passwordValidation.errors.new_password1) || (updateUserPasswordErrors && updateUserPasswordErrors.new_password1) || (updateUserPasswordErrors && updateUserPasswordErrors.non_field_errors)? true : false
																}
															/>
															{passwordValidation.touched.new_password1 && validation.errors.new_password1 ? (
																<FormFeedback type="invalid">{validation.errors.new_password1}</FormFeedback>
															) : null}
															{updateUserPasswordErrors && updateUserPasswordErrors.new_password1 ? (<FormFeedback type="invalid">{updateUserPasswordErrors.new_password1}</FormFeedback>) : null}
															{updateUserPasswordErrors && updateUserPasswordErrors.non_field_errors ? (<FormFeedback type="invalid">{updateUserPasswordErrors.non_field_errors}</FormFeedback>) : null}
														</div>
													</Col>

													<Col lg={4}>
														<div>
															<Label htmlFor="new_password2" className="form-label">{t("Confirm Password*")}</Label>
																<Input
																	type="password"
																	className="form-control"
																	id="new_password2"
																	placeholder={t("Confirm new password")}
																	name='new_password2'
																	validate={{
																		required: { value: true },
																	}}
																	onChange={passwordValidation.handleChange}
																	onBlur={passwordValidation.handleBlur}
																	value={passwordValidation.values.new_password2 || ""}
																	invalid={
																		(passwordValidation.errors.new_password2) || (updateUserPasswordErrors && updateUserPasswordErrors.new_password2)? true : false
																	}
																/>
																{passwordValidation.touched.new_password2 && validation.errors.new_password2 ? (
																	<FormFeedback type="invalid">{validation.errors.new_password2}</FormFeedback>
																) : null}
																{updateUserPasswordErrors && updateUserPasswordErrors.new_password2 ? (<FormFeedback type="invalid">{updateUserPasswordErrors.new_password2}</FormFeedback>) : null}
														</div>
													</Col>

													<Col lg={12}>
														<div className="text-end">
															<button type="submit" className="btn btn-success">{t("Change Password")}</button>
														</div>
													</Col>

												</Row>

											</Form>
											
										</TabPane>

									</TabContent>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default EditUser;