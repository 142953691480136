import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";

import Users from "../Pages/Users/index";
import UserProfile from "../Pages/Users/UserProfile";
import BasicLockScreen from '../Pages/AuthenticationInner/LockScreen/BasicLockScr';

const authProtectedRoutes = [
  { path: "/users", component: <Users /> },
  { path: "/user-profile", component: <UserProfile /> },
  { path: "/auth-lockscreen", component: <BasicLockScreen />},
  { path: "/", exact: true, component: <Navigate to="/devices" /> },
  { path: "*", component: <Navigate to="/devices" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };