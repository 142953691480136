import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
const ProfileDropdown = () => {
	const { t } = useTranslation();
	const [userFirstName, setUserFirstName] = useState("");
	const [userLastName, setUserLastName] = useState("");
	const [userEmail, setUserEmail] = useState("");

	useEffect(() => {

		const first_name = Cookies.get('first_name');
		const last_name = Cookies.get('last_name');
		const email = Cookies.get('email');

		if(first_name && last_name && email){
			setUserFirstName(first_name);
			setUserLastName(last_name);
			setUserEmail(email);
		}
	}, []);

	//Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};
	return (
		<React.Fragment>
			<Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
				<DropdownToggle tag="button" type="button" className="btn">
					<span className="d-flex align-items-center">
						<div className="avatar-sm img-thumbnail rounded-circle flex-shrink-0">
							<div className="avatar-title text-uppercase border rounded-circle bg-light text-primary fs-16">
								{userFirstName.charAt(0) + userLastName.charAt(0)}
							</div>
						</div>
						<span className="text-start ms-xl-2">
							<span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userFirstName} {userLastName}</span>
							<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userEmail}</span>
						</span>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<h6 className="dropdown-header">{t("Welcome")} {userFirstName} {userLastName}!</h6>
					<div className="dropdown-divider"></div>
					{/*<DropdownItem className='p-0'>
						<Link to={process.env.PUBLIC_URL + "/auth-lockscreen"} className="dropdown-item">
							<i
								className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span>
						</Link>
					</DropdownItem>*/}
					<DropdownItem className='p-0'>
						<Link to={process.env.PUBLIC_URL + "/logout"} className="dropdown-item">
							<i
								className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
									className="align-middle" data-key="t-logout">{t("Logout")}</span>
						</Link>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

export default ProfileDropdown;