import React, { useState, useMemo, useCallback } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import WebServiceStatus from "./WebServiceStatus";
import { hasGroup } from "../../api/session_helper";

import 'react-toastify/dist/ReactToastify.css';

const WebServiceTable = ({
	data,
	isLoading,
	isSuccess,
	error,
	onMultipleSelected,
	onDetailsPressed,
	onDownloadPressed
}) => {

	const { t } = useTranslation();

	const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);

	// Checked All
	const checkedAll = useCallback(() => {
		const checkall = document.getElementById("checkBoxAll");
		const ele = document.querySelectorAll(".orderCheckBox");

		if (checkall.checked) {
			ele.forEach((ele) => {
				ele.checked = true;
			});
		} else {
			ele.forEach((ele) => {
				ele.checked = false;
			});
		}
		deleteCheckbox();
	}, []);

	const deleteMultiple = () => {
		const checkall = document.getElementById("checkBoxAll");
		selectedCheckBoxDelete.forEach((element) => {
			dispatch(onDeleteOrder(element.value));
			setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
		});
		checkall.checked = false;
		setIsMultiDeleteButton(false);
	};

	const deleteCheckbox = (order) => {
		if(selectedCheckBoxDelete.includes(parseInt(order))){
			let length = selectedCheckBoxDelete.length - 1;
			setSelectedCheckBoxDelete(
				selectedCheckBoxDelete.filter(id => id !== parseInt(order))
			);
			onMultipleSelected(length);
		} else {
			setSelectedCheckBoxDelete([
				...selectedCheckBoxDelete,
				parseInt(order)
			]);
			let length = selectedCheckBoxDelete.length + 1;
			onMultipleSelected(length);
		}
	};

	const onClickDelete = (order) => {
		setOrder(order);
		setDeleteModal(true);
	};

	const toggle = useCallback(() => {
		
	});

	const handleOrderClick = useCallback((arg) => {
		const order = arg;
		setOrder({
			_id: order._id,
			orderId: order.orderId,
			customer: order.customer,
			product: order.product,
			orderDate: order.orderDate,
			ordertime: order.ordertime,
			amount: order.amount,
			payment: order.payment,
			status: order.status
		});
		setIsEdit(true);
		toggle();
	}, [toggle]);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const detailsPressed = useCallback((integration) => {
		onDetailsPressed(integration);
	});
	
	// Column
	const columns = useMemo(
		() => [
			/*{
				Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
				Cell: (cellProps) => {
					return <input 
						type="checkbox" 
						className="orderCheckBox form-check-input" 
						checked={selectedCheckBoxDelete.includes(cellProps.row.original.id)} 
						value={cellProps.row.original.id} 
						onChange={(e) => { deleteCheckbox(e.target.value); }} />;
				},
				id: '#',
			},*/
			{
				Header: t("Name"),
				accessor: "name",
				filterable: false,
				Cell: (cell) => {
					return <Link to={`${cell.row.original.id}`} className="fw-medium link-primary h5">{cell.value}</Link>;
				},
			},
			{
				Header: t("URL"),
				accessor: "url",
				Cell: (cell) => (
					<small> {cell.value}</small>
				),
			},
			{
				Header: t("Companies count"),
				accessor: "companies_count",
				Cell: (cell) => (
					<div className="fs-14 mb-1">{cell.value}</div>
				),
			},
			{
				Header: t("Integration count"),
				accessor: "devices_count",
				Cell: (cell) => (
					<div className="fs-14 mb-1">{cell.value}</div>
				),
			},
			{
				Header: t("Created at"),
				accessor: "created_at",
				Cell: (order) => (
					<>
						{handleValidDate(order.row.original.created_at)},
						<small className="text-muted"> {handleValidTime(order.row.original.created_at)}</small>
					</>
				),
			},
			{
				Header: t('Status'),
				accessor: 'status',
				Cell: (order) => {
					return <WebServiceStatus status={order.row.original.status} status_text={order.row.original.status_text} />;
				}
			},
			{
				Header: t("Payload"),
				Cell: (cellProps) => {
					if(hasGroup(['Platform Manager'])){
						return (
							<ul className="list-inline hstack gap-2 mb-0">
								<Button 
									color="link" 
									onClick={() => {
										const integrationData = cellProps.row.original;
										detailsPressed(integrationData);
									}} 
									className="" 
									type="button" 
									id="password-addon">
									<i className="ri-eye-fill align-middle"></i>
								</Button>
								{cellProps.row.original.last_send_input && (
									<Button 
										color="link" 
										onClick={() => {
											const integrationData = cellProps.row.original;
											onDownloadPressed(integrationData);
										}} 
										className="" 
										type="button" 
										id="password-addon">
										<i className="ri-download-fill align-middle"></i>
									</Button>
								)}
							</ul>
						);
					} else {
						return <></>
					}
				},
			},
		],
		[handleOrderClick, checkedAll]
	);

	return (
		<>
			<OrdersTableBase
				columns={columns}
				data={data}
				isLoading={isLoading}
				isSuccess={isSuccess}
				error={error}
				manualPagination={true}
				customPageSize={25}
				divClass="table-responsive table-card mb-1"
				tableClass="align-middle table-nowrap"
				theadClass="table-light text-muted"
			/>
		</>
	);
};

export default WebServiceTable;

