import Cookies from 'js-cookie';

export const hasGroup = (names) => {
    const groups = Cookies.get('groups');
    if(groups){
        const groupsObj = JSON.parse(groups);
        for(let id in names){
            if(groupsObj.find((group) => group === names[id])){
                return true;
            }
        }
    }
    return false;
};