import React, { useEffect, useState } from 'react';
import { 
	Card, 
	CardHeader, 
	CardBody, 
	Row,
	Col,
	Container,
	Offcanvas, 
  OffcanvasBody
 } from "reactstrap";

import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { isEmpty } from "lodash";

import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Pagination from "../../Components/Commons/Pagination";
import Loader from "../../Components/Commons/Loader";
import WebServiceStatusColor from "../WebServices/WebServiceStatusColor";
import DashboardChart from "./DashboardChart";
import DeviceStatusColor from "../Devices/DeviceStatusColor";
import SendInputsTable from "./SendInputsTable";

import * as FileSaver from "file-saver";
import Cookies from 'js-cookie';
import axios from "axios";

import { 
	useGetWebServicesStatisticsQuery,
	useGetDevicesStatisticsQuery,
	useGetIntegrationsStatisticsQuery,
	useGetWebServicesDevicesStatisticsQuery,
	useGetSendInputsQuery
 } from '../../api/api'

const DashboardDetail = () => {

	let { id } = useParams();
	const { t } = useTranslation();

	const [currentPage, setCurrentPage] = useState(1);

	const [statusAmounts, setStatusAmounts] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [colors, setColors] = useState([]);

	const [webServiceStatusAmounts, setWebServiceStatusAmounts] = useState([]);
	const [webServiceStatuses, setWebServiceStatuses] = useState([]);
	const [webServiceColors, setWebServiceColors] = useState([]);

	const [integrationStatusAmounts, setIntegrationStatusAmounts] = useState([]);
	const [integrationStatuses, setIntegrationStatuses] = useState([]);
	const [integrationColors, setIntegrationColors] = useState([]);

	const [webServiceDevicesAmounts, setWebServiceDevicesAmounts] = useState([]);
	const [webServiceDevices, setWebServiceDevices] = useState([]);
	const [webServiceDevicesColors, setWebServiceDevicesColors] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [sideBarSendInput, setSideBarSendInput] = useState(null);

	const { 
		data: devices,
		error: devicesError,
		isFetching: isDevicesLoading,
		isSuccess: isDevicesSuccess
	} = useGetDevicesStatisticsQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
			pollingInterval: 30000,
		}
	);

	const { 
		data: webServices,
		error: webServicesError,
		isFetching: isWebServicesLoading,
		isSuccess: isWebServicesSuccess
	} = useGetWebServicesStatisticsQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
			pollingInterval: 30000,
		}
	);

	const { 
		data: webServicesDevices,
		error: webServicesDevicesError,
		isFetching: isWebServicesDevicesLoading,
		isSuccess: isWebServicesDevicesSuccess
	} = useGetWebServicesDevicesStatisticsQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
			pollingInterval: 30000,
		}
	);

	const { 
		data: integrations,
		error: integrationsError,
		isFetching: isIntegrationsLoading,
		isSuccess: isIntegrationsSuccess
	} = useGetIntegrationsStatisticsQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
			pollingInterval: 30000,
		}
	);

	useEffect(() => {
		if (devices) {
			let amounts = devices.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setStatusAmounts(amounts);

			let statuses = devices.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setStatuses(statuses);

			const colors = devices.filter((status) => status.count > 0).map((status) =>
				DeviceStatusColor({status: status.value})
			);
			setColors(colors);

		}
		if (webServices) {

			let orderSetAmounts = webServices.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setWebServiceStatusAmounts(orderSetAmounts);

			let orderSetStatuses = webServices.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setWebServiceStatuses(orderSetStatuses);

			const orderSetColors = webServices.filter((status) => status.count > 0).map((status) =>
				WebServiceStatusColor({status: status.value})
			);
			setWebServiceColors(orderSetColors);

		}
		if (webServicesDevices) {

			let orderSetAmounts = webServicesDevices.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setWebServiceDevicesAmounts(orderSetAmounts);

			let orderSetStatuses = webServicesDevices.filter((status) => status.count > 0).map((status) =>
				status.value
			);
			setWebServiceDevices(orderSetStatuses);

			const orderSetColors = webServicesDevices.filter((status) => status.count > 0).map((status, index) => {
				if (index === 0) {
					return getChartColorsArray('["--vz-primary"]')[0];
				} else if (index === 1) {
					return getChartColorsArray('["--vz-dark"]')[0];
				} else if (index === 2) {
					return getChartColorsArray('["--vz-success"]')[0];
				} else if (index === 3) {
					return getChartColorsArray('["--vz-danger"]')[0];
				} else if (index === 4) {
					return getChartColorsArray('["--vz-warning"]')[0];
				} else if (index === 5) {
					return getChartColorsArray('["--vz-secondary"]')[0];
				} else if (index === 6) {
					return getChartColorsArray('["--vz-light"]')[0];
				}
			});
			setWebServiceDevicesColors(orderSetColors);

		}
		if (integrations) {

			let integrationsAmounts = integrations.filter((status) => status.count > 0).map((status) => 
				status.count
			);
			setIntegrationStatusAmounts(integrationsAmounts);

			let integrationsStatuses = integrations.filter((status) => status.count > 0).map((status) =>
				status.display
			);
			setIntegrationStatuses(integrationsStatuses);

			const integrationsColors = integrations.filter((status) => status.count > 0).map((status) =>
				WebServiceStatusColor({status: status.value})
			);
			setIntegrationColors(integrationsColors);

		}
	}, [devices, webServices, webServicesDevices, integrations]);

	const { 
		data: sendInputs,
		error: sendInputsError,
		isFetching: isSendInputsLoading,
		isSuccess: isSendInputsSuccess
	} = useGetSendInputsQuery(
		{ 
			page: currentPage,
			page_size: 10
		}, 
		{
			refetchOnMountOrArgChange: true,
			skip: false,
			pollingInterval: 30000,
		}
	);

	const handleExport = (send_input) => {
		let token = JSON.parse(Cookies.get("authUser")).token;
		axios.get(`send-inputs/${send_input.reference}/export_data/`, 
			{
				headers: { 'Authorization': `Token ${token}` },
				responseType: 'blob'
			}
		).then((response) => {
			if(send_input.web_service.protocol_kind === 2){
				saveAs(response, `payload_${Date.now()}.xml`);
			} else {
				saveAs(response, `payload_${Date.now()}.json`);
			}
		});
	};

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Offcanvas
						isOpen={isOpen}
						direction="end"
						toggle={() => setIsOpen(!isOpen)}
						className="offcanvas-end border-0"
						tabIndex="-1"
						id="member-overview"
					>
						<OffcanvasBody className="profile-offcanvas p-0">
							<div className="p-3">
								<h5 className="fs-15 mb-3">{t("Send Input Details")}</h5>
								<div className="mb-3">
									<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Payload Sent")}</p>
									<h6>
										{sideBarSendInput ? sideBarSendInput.payload : ''}
									</h6>
								</div>
								<div className="mb-3">
									<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Web Service Return")}</p>
									<h6>
										{sideBarSendInput ? JSON.stringify(sideBarSendInput.response) : ''}
									</h6>
								</div>
								<div className="mb-3">
									<p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Error Response")}</p>
									<h6>
										{sideBarSendInput ? sideBarSendInput.errors : ''}
									</h6>
								</div>
							</div>
						</OffcanvasBody>
					</Offcanvas>
					<BreadCrumb title={t("dashboard")} pageTitle={t("start")} />
					<Row>
						<Col xxl={6}>
							<Card id="statistics1" className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Devices")}</h4>
								</CardHeader>
								<CardBody>
									<div dir="ltr">
										{isDevicesLoading && <Loader/>}
										{isDevicesSuccess && (
											<>
												<DashboardChart 
													series={statusAmounts}
													labels={statuses}
													colors={colors}
													itemName={t('Devices')} />
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>

						<Col xxl={6}>
							<Card id="statistics2" className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("WebServices")}</h4>
								</CardHeader>
								<CardBody>
									<div dir="ltr">
										{isWebServicesLoading && <Loader/>}
										{isWebServicesSuccess && (
											<>
												<DashboardChart 
													series={webServiceStatusAmounts}
													labels={webServiceStatuses}
													colors={webServiceColors}
													itemName={t('WebServices')} />
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xxl={6}>
							<Card id="statistics3" className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Integrations Status")}</h4>
								</CardHeader>
								<CardBody>
									<div dir="ltr">
										{isWebServicesLoading && <Loader/>}
										{isWebServicesSuccess && (
											<>
												<DashboardChart
													series={integrationStatusAmounts}
													labels={integrationStatuses}
													colors={integrationColors}
													itemName={t('Integrations')} />
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>

						<Col xxl={6}>
							<Card id="statistics4" className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Integrations Distribution")}</h4>
								</CardHeader>
								<CardBody>
									<div dir="ltr">
										{isWebServicesDevicesLoading && <Loader/>}
										{isWebServicesDevicesSuccess && (
											<>
												<DashboardChart
													series={webServiceDevicesAmounts}
													labels={webServiceDevices}
													colors={webServiceDevicesColors}
													itemName={t('Devices')} />
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xxl={12}>
							<Card id="orderList">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Last Data Send Tasks")}</h4>
								</CardHeader>
								<CardBody>
									<SendInputsTable
										data={(sendInputs && sendInputs.results || [])}
										isLoading={isSendInputsLoading}
										isSuccess={isSendInputsSuccess}
										error={sendInputsError}
										isGlobalFilter={false}
										customPageSize={25}
										divClass="table-responsive table-card mb-1"
										tableClass="align-middle table-nowrap"
										theadClass="table-light text-muted"
										onDetailsPressed={(sendInput) => {
											setSideBarSendInput(sendInput);
											setIsOpen(true);
										}}
										onDownloadPressed={(sendInput) => {
											handleExport(sendInput);
										}}
									/>
									{sendInputs && !isEmpty(sendInputs.results) && (
										<Pagination
											totalRows={sendInputs.count}
											pageChangeHandler={setCurrentPage}
											rowsPerPage={25}
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default DashboardDetail;