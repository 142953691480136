import React, {useState} from 'react';
import { Card, CardHeader, CardBody, Table } from "reactstrap";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { hasGroup } from "../../api/session_helper";

import Loader from "../../Components/Commons/Loader";
import DeviceStatus from "./DeviceStatus";
import GPSDeviceStatus from "./GPSDeviceStatus";
import ChangeCompanyModal from "./ChangeCompanyModal";

import { useGetDeviceByIdQuery } from '../../api/api'

import * as moment from "moment";

const DeviceSummary = () => {

	let { id } = useParams();
	const { t } = useTranslation();

	const [modal, setModal] = useState(false);

	const { 
		data: device,
		error,
		isFetching: isDeviceLoading,
		isSuccess: isDeviceSuccess
	} = useGetDeviceByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleSuccessAssign = (importObject) => {
		setModal(false);
		toast.success(t(`Company assigned successfully`), {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			bodyClassName: "fs-24",}
		);
	};

	const handleAssignError = () => {
		setModal(false);
		toast.error(t(`There was an error while assigning the company`), {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			bodyClassName: "fs-24",}
		);
	};

	return (
		<React.Fragment>
			<ChangeCompanyModal 
				deviceId={id}
				show={modal}
				onClose={() => setModal(false)}
				onAssignSuccess={handleSuccessAssign}
				onAssignError={handleAssignError}
			/>
			<Card className="mb-3">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Device Details")}</h4>
					{hasGroup(['Platform Manager']) && (
						<div className="flex-shrink-0">
							<button
								type="button"
								className="btn btn-success btn-sm"
								id="create-btn"
								onClick={() => { setModal(true); }}>
								<i className="ri-add-line align-bottom me-1"></i> {t("Assign Company")}
							</button>
						</div>
					)}
				</CardHeader>
				<CardBody>
					{isDeviceLoading && <Loader/>}
					{isDeviceSuccess && (
						<div className="table-responsive table-card">
							<Table className="table mb-0">
								<tbody>
									<tr>
										<td className="fw-medium text-muted">{t("Plate")}</td>
										<td className="overview-industryType">{device.name}</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Status")}</td>
										<td className="overview-industryType">
											<DeviceStatus status={device.status} status_text={device.status_name} />
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("GPS Status")}</td>
										<td className="overview-industryType">
											<GPSDeviceStatus status={device.gps_status} status_text={device.gps_status_name} />
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Created at")}</td>
										<td className="overview-industryType">
											{handleValidDate(device.created_at)}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("IMEI")}</td>
										<td className="overview-industryType">
											{device.imei}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("RUT")}</td>
										<td className="overview-company_location">
											{device.rut}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Company")}</td>
										<td>{device.company ? device.company.name : ''}</td>
									</tr>
								</tbody>
							
							</Table>
						</div>
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default DeviceSummary;