import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Commons/BreadCrumb';
import CompanySummary from "./CompanySummary";
import AllowedWebServices from "./AllowedWebServices";
import { useTranslation, initReactI18next } from "react-i18next";

const CompanyDetail = () => {

	const { t } = useTranslation();

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Company Details")} pageTitle={t("Companies")} />
					<Row>
						<Col xxl={3}>
							<CompanySummary />
						</Col>
						<Col xxl={9}>
							<AllowedWebServices />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default CompanyDetail;