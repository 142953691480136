import { api } from "../config";
// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { omitBy, isNull } from "lodash";
import Cookies from 'js-cookie';

// Define a service using a base URL and expected endpoints
export const ngpsApi = createApi({
  reducerPath: 'NGPSApi',
  tagTypes: ['Devices', 'WebServices', 'Users', 'Companies', 'SendInputs'],
  baseQuery: fetchBaseQuery({
    baseUrl: api.API_URL,
    prepareHeaders: (headers) => {
      if(Cookies.get("authUser")){
        let token = JSON.parse(Cookies.get("authUser")).token;
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({

    auth: builder.mutation({
      query: (body) => ({
        url: `auth/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        let errorString;
        if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
        return errorString;
      },
      invalidatesTags: ['Users', 'WebServices', 'Devices'],
    }),

    getWebServices: builder.query({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `web-services/?${params}`;
      },
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['WebServices'],
    }),

    getWebServiceStatusesTypes: builder.query({
      query: () => ({
        url: `web-services/get_statuses/`,
      }),
    }),

    updateWebService: builder.mutation({
      query: ({id, body}) => ({
        url: `web-services/${id}/`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['WebServices'],
    }),

    getAllWebServices: builder.query({
      query: () => `web-services/get_all_web_services/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['WebServices'],
    }),

    getWebServicesStatistics: builder.query({
      query: () => `web-services/get_web_services_statistics/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['WebServices'],
    }),

    getWebServicesDevicesStatistics: builder.query({
      query: () => `web-services/get_web_services_devices_statistics/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['WebServices'],
    }),

    getAllIntegrations: builder.query({
      query: () => `web-services/get_all_integrations/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['WebServices'],
    }),

    getIntegrationsStatistics: builder.query({
      query: () => `web-services/get_integrations_statistics/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['WebServices'],
    }),

    getWebServiceById: builder.query({
      query: (id) => `web-services/${id}`,
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: (result, error, id) => {
        return [{ type: 'WebServices', id }];
      }
    }),

    updateWebServiceById: builder.mutation({
      query: ({id, body}) => ({
        url: `web-services/${id}/`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['WebServices'],
    }),

    getSendInputs: builder.query({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `send-inputs/?${params}`;
      },
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['SendInputs'],
    }),

    getSendInputPayloadById: builder.query({
      query: (id) => `send-inputs/${id}/export_data/`,
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: (result, error, id) => {
        return [{ type: 'SendInputs', id }];
      }
    }),

    getDevices: builder.query({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `devices/?${params}`;
      },
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Devices'],
    }),

    getAllDevices: builder.query({
      query: () => `devices/get_all_devices/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Devices'],
    }),

    getDevicesStatistics: builder.query({
      query: () => `devices/get_gps_statistics/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Devices'],
    }),

    getDeviceById: builder.query({
      query: (id) => `devices/${id}`,
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: (result, error, id) => {
        return [{ type: 'Devices', id }];
      }
    }),

    createDevice: builder.mutation({
      query: (body) => ({
        url: `devices/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Devices'],
    }),

    updateDevicesFromGPSWox: builder.mutation({
      query: (body) => ({
        url: `devices/create_or_update_devices/`,
        method: 'POST',
        body,
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Devices'],
    }),

    integrateDeviceToWebService: builder.mutation({
      query: ({id, body}) => ({
        url: `devices/${id}/create_integration/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        let errorString;
        if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
        return errorString;
      },
      invalidatesTags: ['Devices'],
    }),

    disableDeviceIntegration: builder.mutation({
      query: ({id, body}) => ({
        url: `devices/${id}/disable_integration/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        let errorString;
        if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
        return errorString;
      },
      invalidatesTags: ['Devices'],
    }),

    assignDeviceToCompany: builder.mutation({
      query: ({id, body}) => ({
        url: `devices/${id}/assign_compamy/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        let errorString;
        if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
        return errorString;
      },
      invalidatesTags: ['Devices'],
    }),

    exportDevices: builder.mutation({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `devices/export_data/?${params}`;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
    }),

    /*exportDevices: builder.query({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `devices/export_data/?${params}`;
      },
    }),*/

    getDeviceStatusesTypes: builder.query({
      query: () => ({
        url: `devices/get_statuses/`,
      }),
    }),

    updateDevice: builder.mutation({
      query: ({id, body}) => ({
        url: `devices/${id}/`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Devices'],
    }),

    getUsers: builder.query({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `users/?${params}`;
      },
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Users'],
    }),

    getAllUsers: builder.query({
      query: () => `users/get_all/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    getUserProfile: builder.query({
      query: () => `users/profile/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Users'],
    }),

    createUser: builder.mutation({
      query: (body) => ({
        url: `users/`,
        method: 'POST',
        /*headers: {
          'content-type': 'text/plain',
        },*/
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Users'],
    }),

    updateUser: builder.mutation({
      query: ({id, body}) => ({
        url: `users/${id}/`,
        method: 'PATCH',
        /*headers: {
          'content-type': 'text/plain',
        },*/
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Users'],
    }),

    deleteUser: builder.mutation({
			query: (id) => ({
				url: `users/${id}/`,
				method: 'DELETE'
			}),
			transformErrorResponse: (response, meta, arg) => {
				return response.data;
			},
			invalidatesTags: ['Users'],
		}),

    getUserById: builder.query({
      query: (id) => `users/${id}`,
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Users'],
    }),

    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: `update-user-password/`,
        method: 'PATCH',
        /*headers: {
          'content-type': 'text/plain',
        },*/
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
    }),

    getUserGroups: builder.query({
      query: () => `users/get_user_groups/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    getCompanies: builder.query({
      query: (args) => {
        let params = new URLSearchParams(omitBy(args, isNull)).toString();
        return `companies/?${params}`;
      },
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Companies'],
    }),

    getAllCompanies: builder.query({
      query: () => `companies/get_all_companies/`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Companies'],
    }),

    getCompanyById: builder.query({
      query: (id) => `companies/${id}`,
      transformErrorResponse: (response, meta, arg) => {
        return response;
      },
      providesTags: ['Companies'],
    }),

    createCompany: builder.mutation({
      query: (body) => ({
        url: `companies/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Companies'],
    }),

    updateCompany: builder.mutation({
      query: ({id, body}) => ({
        url: `companies/${id}/`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Companies'],
    }),

    getWebServicesByCompany: builder.query({
      query: (id) => ({
        url: `companies/${id}/get_all_webservices/`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Companies'],
    }),

    getAllowedWebServicesByCompany: builder.query({
      query: (id) => ({
        url: `companies/${id}/get_allowed_webservices/`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      invalidatesTags: ['Companies'],
    }),

    allowWebService: builder.mutation({
      query: ({id, body}) => ({
        url: `companies/${id}/allow_web_service/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        let errorString;
        if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
        return errorString;
      },
      invalidatesTags: ['Companies'],
    }),

    denyWebService: builder.mutation({
      query: ({id, body}) => ({
        url: `companies/${id}/deny_web_service/`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        let errorString;
        if(response.data instanceof Array){
					errorString = response.data.join(', ');
				} else if(response.data && response.data.non_field_errors && response.data.non_field_errors instanceof Array){
					errorString = response.data.non_field_errors.join(', ');
				} else if(response.error){
					errorString = response.error;
				} else {
					errorString = "Unknown error"
				}
        return errorString;
      },
      invalidatesTags: ['Companies'],
    }),

    getSetting: builder.query({
      query: () => `settings`,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    updateSetting: builder.mutation({
      query: (body) => ({
        url: `settings/update_settings/`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
    }),

    
    
  }),
})

export const {
  useAuthMutation,
  useGetDeviceByIdQuery,
  useGetDevicesQuery,
  useGetAllDevicesQuery,
  useGetDevicesStatisticsQuery,
  useUpdateDeviceMutation,
  useCreateDeviceMutation,
  useUpdateDevicesFromGPSWoxMutation,
  useIntegrateDeviceToWebServiceMutation,
  useDisableDeviceIntegrationMutation,
  useAssignDeviceToCompanyMutation,
  useExportDevicesMutation,
  useGetDeviceStatusesTypesQuery,
  useGetWebServicesQuery,
  useGetAllWebServicesQuery,
  useGetWebServicesStatisticsQuery,
  useGetWebServicesDevicesStatisticsQuery,
  useGetAllIntegrationsQuery,
  useGetIntegrationsStatisticsQuery,
  useGetWebServiceByIdQuery,
  useUpdateWebServiceByIdMutation,
  useGetSendInputsQuery,
  useGetSendInputPayloadByIdQuery,
  useGetWebServicesByCompanyQuery,
  useGetAllowedWebServicesByCompanyQuery,
  useAllowWebServiceMutation,
  useDenyWebServiceMutation,
  useUpdateWebServiceMutation,
  useGetWebServiceStatusesTypesQuery,
  useGetUsersQuery,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useGetUserProfileQuery,
  useGetUserGroupsQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserPasswordMutation,
  useGetCompaniesQuery,
  useGetAllCompaniesQuery,
  useGetCompanyByIdQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useGetSettingQuery,
  useUpdateSettingMutation,
} = ngpsApi