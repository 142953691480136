import React, { Fragment, useState, useMemo, useCallback, useEffect } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import {
  Label, Input
} from "reactstrap";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import WebServiceStatus from "../WebServices/WebServiceStatus";
import { useTranslation, initReactI18next } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

const AllowedWebServiceTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  onMultipleSelected,
  allowTriggered,
}) => {

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteOrder(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    checkall.checked = false;
    setIsMultiDeleteButton(false);
  };

  const deleteCheckbox = (order) => {
    if(selectedCheckBoxDelete.includes(parseInt(order))){
      let length = selectedCheckBoxDelete.length - 1;
      setSelectedCheckBoxDelete(
        selectedCheckBoxDelete.filter(id => id !== parseInt(order))
      );
      onMultipleSelected(length);
    } else {
      setSelectedCheckBoxDelete([
        ...selectedCheckBoxDelete,
        parseInt(order)
      ]);
      let length = selectedCheckBoxDelete.length + 1;
      onMultipleSelected(length);
    }
  };

  const webServiceTriggered = (webservice, enabled) => {
    allowTriggered(webservice, enabled);
  };

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`${cell.row.original.id}`} className="fw-medium link-primary h5">{cell.value}</Link>;
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (cell) => (
          <>
            {handleValidDate(cell.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(cell.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t('Status'),
        accessor: 'status',
        Cell: (cell) => {
          return <WebServiceStatus status={cell.row.original.status} status_text={cell.row.original.status_text} />;
        }
      },
      {
        Header: t("Action"),
        Cell: (cell) => {
          return (
            <div className="form-check form-switch form-switch-right form-switch-md">
              <Label className="form-label text-muted">{t("Allow")}</Label>
              <Input 
                className="form-check-input code-switcher" 
                type="checkbox"
                checked={cell.row.original.is_current_company_active}
                onChange={(e) => {
                  allowTriggered(cell.row.original, e.target.checked); 
                }}
              />
              
            </div>
          );
        },
      },
    ],
    [checkedAll, data]
  );

  return (
    <>
      <OrdersTableBase
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        manualPagination={false}
        customPageSize={25}
        divClass="table-responsive table-card mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </>
  );
};

export default AllowedWebServiceTable;

