import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Table,
	Badge, Offcanvas, OffcanvasBody
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import IntegrationsTable from "./IntegrationsTable";
import Loader from "../../Components/Commons/Loader";
import CreateIntegrationModal from "./CreateIntegrationModal";
import ErrorModal from "../../Components/Commons/ErrorModal";
import { toast } from 'react-toastify';
import { useTranslation, initReactI18next } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

import { useGetDeviceByIdQuery } from '../../api/api'

const DeviceIntegrations = () => {

  let { id } = useParams();
  const { t } = useTranslation();

  const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sideBarIntegration, setSideBarIntegration] = useState(null);
  const [modal, setModal] = useState(false);

  const { 
    data: device,
    error,
    isFetching: isDeviceLoading,
    isSuccess: isDeviceSuccess
  } = useGetDeviceByIdQuery(
    id,
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const handleSuccessImported = (importObject) => {
		setModal(false);
    toast.success(t(`Integration successfully created`), {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			bodyClassName: "fs-24",}
		);
	};

	const handleImportedError = (error) => {
	let errorString;
				
		if(error.data instanceof Array){
			errorString = error.data.join(', ');
		} else if(error.error){
			errorString = error.error;
		} else {
			errorString = t("Unknown error")
		}
		setModal(false);
		setErrorTitle(t("Error when trying to integrate the webservice"));
		setErrorDescription(errorString);
		setErrorModal(true);
	};

  document.title = t("WebService Data Replication");

  return (
		<React.Fragment>
      <CreateIntegrationModal
        device={device ?? ''}
        show={modal}
        onClose={() => setModal(false)}
        onOrderImportSuccess={handleSuccessImported}
        onOrderImportError={handleImportedError}
      />
      <ErrorModal
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        title={errorTitle}
        description={errorDescription}
      />
      <Offcanvas
        isOpen={isOpen}
        direction="end"
        toggle={() => setIsOpen(!isOpen)}
        className="offcanvas-end border-0"
        tabIndex="-1"
        id="member-overview"
      >
        <OffcanvasBody className="profile-offcanvas p-0">
          <div className="p-3">
            <h5 className="fs-15 mb-3">{t("Integration Details")}</h5>
            <div className="mb-3">
              <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Payload Sent")}</p>
              <h6>{sideBarIntegration && sideBarIntegration.web_service.last_send_input ? sideBarIntegration.web_service.last_send_input.payload : ''}</h6>
            </div>
            <div className="mb-3">
              <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Web Service Return")}</p>
              <h6>{sideBarIntegration && sideBarIntegration.web_service.last_send_input ? JSON.stringify(sideBarIntegration.web_service.last_send_input.response) : ''}</h6>
            </div>
            <div className="mb-3">
              <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">{t("Last Error Response")}</p>
              <h6>{sideBarIntegration && sideBarIntegration.web_service.last_send_input ? sideBarIntegration.web_service.last_send_input.errors : ''}</h6>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      <Card id="orderList">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t("Integrations")} <Badge color="primary" className="ms-1">{device != null ? device.integrations.length : '...'}</Badge></h4>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-success btn-sm"
              id="create-btn"
              onClick={() => { setModal(true); }}>
              <i className="ri-add-line align-bottom me-1"></i> {t("Create Integrations")}
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <IntegrationsTable
            data={(device && device.integrations || [])}
            isLoading={isDeviceLoading}
            isSuccess={isDeviceSuccess}
            error={error}
            isGlobalFilter={false}
            customPageSize={25}
            onDetailsPressed={(integration) => {
              setModal(false);
              setSideBarIntegration(integration);
              setIsOpen(true);
            }}
            divClass="table-responsive table-card mb-1"
            tableClass="align-middle table-nowrap"
            theadClass="table-light text-muted"
          />					
        </CardBody>
      </Card>
		</React.Fragment>
  );
};

export default DeviceIntegrations;