import {
  postAuth,
} from "../../../api/backend_helper";
import Cookies from 'js-cookie';

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

export const loginUser = (user, history) => async (dispatch) => {
  try {
    let response;
    response = postAuth({
      username: user.email,
      password: user.password,
    });

    var data = await response;
    if (data) {
      Cookies.set('authUser', JSON.stringify(data), { expires: new Date(new Date().getTime() + 60000) });
      dispatch(loginSuccess(data));
      history('/orders')
  
      /*var finallogin = JSON.stringify(data);
      finallogin = JSON.parse(finallogin)
      data = finallogin.data;
      if (finallogin.status === "success") {
        dispatch(loginSuccess(data));
        history('/orders')
      } else {
        dispatch(apiError(finallogin));
      }*/
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    Cookies.remove('authUser');
    dispatch(logoutUserSuccess(true));

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};