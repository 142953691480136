import React from 'react';

/*
OUTDATED = 0
UPDATED = 1
DISABLED = -1

*/

//Constants
const IntegrationStatus = ({ status, status_text}) => {
	if (status === 0) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	} else if (status === -1) {
		return <div className={"badge bg-dark"}>{status_text}</div>;
	}
};

export default IntegrationStatus;