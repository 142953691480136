import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useTranslation, initReactI18next } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import withRouter from "../../Components/Commons/withRouter";
import { hasGroup } from "../../api/session_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import Cookies from 'js-cookie';

import { 
	useAuthMutation, 
	useGetUserProfileQuery,
 } from '../../api/api'

//import images

const Login = (props) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [userLogin, setUserLogin] = useState([]);
	const [passwordShow, setPasswordShow] = useState(false);
	const [skipProfile, setSkipProfile] = useState(true);

	const [auth, 
	{ error: errorMsg, 
		isLoading: loading,
		isSuccess: isAuthSuccess,
		data: user
	}] = useAuthMutation();

	const { 
		data: profile,
		error: profileError,
		isFetching: profileLoading,
		isSuccess: profileSuccess
	} = useGetUserProfileQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: skipProfile,
		}
	);

	useEffect(() => {
		if (isAuthSuccess) {

			Cookies.set('authUser', JSON.stringify(user), /*{ expires: new Date(new Date().getTime() + 60 *1000) }*/);

			setSkipProfile(false);
		}
	}, [isAuthSuccess]);

	useEffect(() => {
		if (profileSuccess) {			
			Cookies.set('first_name', profile.first_name);
			Cookies.set('last_name', profile.last_name);
			Cookies.set('email', profile.email);
			if(profile.company){
				Cookies.set('company', profile.company.id);
			}
			
			Cookies.set('groups', JSON.stringify(profile.groups));
			
			if(hasGroup(['Platform Manager'])){
				navigate('/dashboard');
			} else {
				navigate('/devices');
			}
			
		}
	}, [profileSuccess]);


	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			email: userLogin.email || '',
			password: userLogin.password || '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required(t("Please Enter Your Email")),
			password: Yup.string().required(t("Please Enter Your Password")),
		}),
		onSubmit: (values) => {
			const login = {
				username: values["email"],
				password: values["password"]
			};
			const formData = new FormData();
			formData.append("username", values["email"])
			formData.append("password", values["password"]);
			auth(formData);
		}
	});

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<ParticlesAuth>
				<div className="auth-page-content">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mt-sm-5 mb-4 text-white">
									<p className="mt-3 fs-24 fw-medium">{t("Webservice data replication platform")}</p>
								</div>
							</Col>
						</Row>

						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="mt-4">
									<CardBody className="p-4">
										<div className="text-center mt-2">
											<h5 className="text-primary">{t("Welcome")}</h5>
											<p className="text-muted">{t("Sign in to enter the platform.")}</p>
										</div>
										{errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null}
										<div className="p-2 mt-4">
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
												action="#">

												<div className="mb-3">
													<Label htmlFor="email" className="form-label">{t("Email")}</Label>
													<Input
														name="email"
														className="form-control"
														placeholder="Enter email"
														type="email"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.email || ""}
														invalid={
															validation.touched.email && validation.errors.email ? true : false
														}
													/>
													{validation.touched.email && validation.errors.email ? (
														<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
													) : null}
												</div>

												<div className="mb-3">
													{/*<div className="float-end">
															<Link to="/forgot-password" className="text-muted">Forgot password?</Link>
													</div>*/}
													<Label className="form-label" htmlFor="password-input">{t("Password")}</Label>
													<div className="position-relative auth-pass-inputgroup mb-3">
														<Input
															name="password"
															value={validation.values.password || ""}
															type={passwordShow ? "text" : "password"}
															className="form-control pe-5"
															placeholder="Enter Password"
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															invalid={
																	validation.touched.password && validation.errors.password ? true : false
															}
														/>
														{validation.touched.password && validation.errors.password ? (
															<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
														) : null}
														<button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
													</div>
												</div>

												<div className="mt-4">
													<Button color="success" disabled={errorMsg ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
														{loading ? <Spinner size="sm" className='me-2'> {t("Loading")}... </Spinner> : null}
														{t("Sign In")}
													</Button>
												</div>
											</Form>

										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default withRouter(Login);