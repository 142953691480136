import React, {useEffect} from 'react';
import { Card, CardHeader, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import Loader from "../../Components/Commons/Loader";

import { useGetCompanyByIdQuery } from '../../api/api'

import * as moment from "moment";

const CompanySummary = () => {

	let { id } = useParams();
	const { t } = useTranslation();

	const { 
		data: company,
		error,
		isFetching: isCompanyLoading,
		isSuccess: isCompanySuccess
	} = useGetCompanyByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	return (
		<React.Fragment>
			<Card className="mb-3">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Company Details")}</h4>
					<div className="flex-shrink-0">
					</div>
				</CardHeader>
				<CardBody>
					{isCompanyLoading && <Loader/>}
					{isCompanySuccess && (
						<div className="table-responsive table-card">
							<Table className="table mb-0">
								<tbody>
									<tr>
										<td className="fw-medium text-muted">{t("Name")}</td>
										<td className="overview-industryType">{company.name}</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Description")}</td>
										<td className="overview-industryType">{company.description}</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Created at")}</td>
										<td className="overview-industryType">
											{handleValidDate(company.created_at)}
										</td>
									</tr>
								</tbody>
							
							</Table>
						</div>
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default CompanySummary;