import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Badge
} from "reactstrap";
import Cookies from 'js-cookie';
import { isEmpty } from "lodash";
import * as moment from "moment";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Pagination from "../../Components/Commons/Pagination";
import DeviceTable from "./DeviceTable";
import DeleteModal from "../../Components/Commons/DeleteModal";
import { useTranslation, initReactI18next } from "react-i18next";
import { hasGroup } from "../../api/session_helper";
import { useAsyncDebounce } from "react-table";
import { toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import 'react-toastify/dist/ReactToastify.css';

import { 
  useGetDevicesQuery, 
  useGetDeviceStatusesTypesQuery,
  useUpdateDevicesFromGPSWoxMutation,
  useGetAllCompaniesQuery
 } from '../../api/api'

const Devices = () => {

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [deviceSearch, setDeviceSearch] = useState(null);
  const [creationStartDate, setCreationStartDate] = useState(null);
  const [creationEndDate, setCreationEndDate] = useState(null);
  const [devicesStatuses, setDevicesStatuses] = useState(null);
  const [company, setCompany] = useState(null);

  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [updateDevices, 
    { error: updateDevicesErrors, 
      isLoading: updateDevicesLoading,
      isSuccess: updateDevicesSuccess,
      data: updateDevicesData
    }] = useUpdateDevicesFromGPSWoxMutation();

  useEffect(() => {
    if (updateDevicesSuccess) {
      toast.success(t(`Device update process started`), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        bodyClassName: "fs-24",}
      );
    }

    if (updateDevicesErrors) {
      toast.error(t(`There was an error while starting the device update process`), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        bodyClassName: "fs-24",}
      );
    }
  }, [updateDevicesSuccess, updateDevicesErrors]);
  
  const { 
    data: companies,
    error: companiesError,
    isFetching: companiesLoading,
    isSuccess: companiesSuccess
  } = useGetAllCompaniesQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  const handleOrderSetState = (selection) => {
    setCurrentPage(1);
    setDevicesStatuses(selection ? [selection.value] : null);
  };

  const handleCompanySelection = (selection) => {
    setCurrentPage(1);
    setCompany(selection ? selection.value : null);
  };
  
  const { 
    data: devicesPages,
    error,
    isFetching: isDeviceLoading,
    isSuccess: isDeviceSuccess
  } = useGetDevicesQuery(
    { 
      page: currentPage,
      filter: deviceSearch,
      creationStartDate: creationStartDate,
      creationEndDate: creationEndDate,
      statuses: devicesStatuses,
      company: (hasGroup(['Company Manager']) && Cookies.get('company')) || company
    }, 
    {
      refetchOnMountOrArgChange: true,
      skip: false,
			pollingInterval: 30000,
    }
  );

  const { 
    data: deviceStatuses,
    error: deviceStatusesErrors,
    isFetching: isDeviceStatusesLoading,
    isSuccess: isDeviceStatusesSuccess
  } = useGetDeviceStatusesTypesQuery(
    {
      refetchOnMountOrArgChange: true,
      skip: false
    }
  );

  const updateDeviceClick = () => {
    updateDevices();
  };

  /*const { 
    data: exportedData,
    error: exportedDataErrors,
    isFetching: exportedDataLoading,
    isSuccess: exportedDataSuccess
  } = useExportOrderSetsQuery(
    {
      refetchOnMountOrArgChange: true,
      skip: !isExportExcel
    }
  );

  useEffect(() => {
    if (exportedData) {
      debugger;
      setIsExportExcel(false);
    }
  }, [exportedData]);*/


  const onChange = useAsyncDebounce((value) => {
    setDeviceSearch(value);
  }, 200);

  document.title = t("WebService Data Replication");

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      
      <Container fluid>

        <BreadCrumb title={t("Devices list")} pageTitle={t("Devices")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Devices")} <Badge color="primary" className="ms-1">{devicesPages != null ? devicesPages.count : '...'}</Badge></h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">

                      <button
                        type="button"
                        className="btn btn-primary me-1"
                        onClick={updateDeviceClick}
                      > {t("Update Devices")}</button>
                      
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Row className="mb-3">
                    <CardBody className="border border-dashed border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col md={3}>
                            <Input
                              name="orderId"
                              id="id-field"
                              className="form-control"
                              placeholder={t("Search")}
                              type="text"
                              onChange={(e) => {
                                setCurrentPage(1);
                                onChange(e.target.value);
                              }}
                            />
                          </Col>

                          <Col md={3}>
                            <Flatpickr
                              className="form-control"
                              id="datepicker-created-at"
                              placeholder={t("Created at")}
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                mode: "range",
                                dateFormat: "DD-MM-YYYY",
                              }}
                              onChange={(date) => {
                                setCurrentPage(1);
                                if(date[0]!== undefined){
                                  const date1 = moment(date[0]).format("DD-MM-YYYY");
                                  setCreationStartDate(date1);
                                } else {
                                  setCreationStartDate(null);
                                }
                                if(date[1]!== undefined){
                                  const date1 = moment(date[1]).format("DD-MM-YYYY");
                                  setCreationEndDate(date1);
                                } else {
                                  setCreationEndDate(null);
                                }
                              }}
                            />
                          </Col>
                          {deviceStatuses && deviceStatuses.statuses && (
                            <Col md={3}>
                              <Select
                                placeholder={t("Device Status")}
                                classNamePrefix="form-select"
                                isLoading={isDeviceStatusesLoading}
                                isClearable={true}
                                name="types"
                                onChange={handleOrderSetState}
                                options={deviceStatuses.statuses.map((eventType, key) => {
                                  return {
                                    ...eventType,
                                    value: eventType.value,
                                    label: eventType.display,
                                  };
                                })}
                              />
                            </Col>
                          )}
                          {hasGroup(['Platform Manager']) && companies && (
                            <Col md={3}>
                              <Select
                                classNamePrefix="form-select"
                                isLoading={companiesLoading}
                                placeholder={t("Assigned Company")}
                                isClearable={true}
                                name="types"
                                onChange={handleCompanySelection}
                                options={companies.map((company, key) => {
                                  return {
                                    ...company,
                                    value: company.id,
                                    label: company.name,
                                  };
                                })}
                              />
                            </Col>
                          )}
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <DeviceTable
                    data={devicesPages != null ? devicesPages.results : []}
                    isLoading={isDeviceLoading}
                    isSuccess={isDeviceSuccess}
                    error={error}
                  />
                </div>
                {devicesPages && !isEmpty(devicesPages.results) && (
                  <Pagination
                    totalRows={devicesPages.count}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={25}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Devices;

