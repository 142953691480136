import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasGroup } from "../../api/session_helper";
import { useTranslation } from "react-i18next";

const Navdata = () => {

    const { t } = useTranslation();

    const history = useNavigate();
    //state data
    const [isOrders, setIsOrders] = useState(false);

    // Authentication
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    
    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Orders') {
            setIsOrders(false);
        }
    }, [
        history,
        iscurrentState,
        isOrders
    ]);

    const menuItems = [
        {
            id: "start",
            label: t("start"),
            isHeader: true,
        },
        /*{
            id: "dashboard",
            label: "Dashboards",
            icon: "ri-dashboard-2-line",
            link: "/index",
            //stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },*/

        {
            id: "dashboard",
            label: t("dashboard"),
            icon: "ri-calendar-event-line",
            link: "/dashboard",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "operation",
            label: t("operation"),
            isHeader: true,
        },
        {
            id: "devices",
            label: t("devices"),
            icon: "ri-router-fill",
            link: "/devices",
            click: function (e) {
                e.preventDefault();
                setIsOrders(!isOrders);
            }
        },
        {
            id: "webservices",
            label: t("webServices"),
            icon: "ri-terminal-box-line",
            link: "/web-services",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "managment",
            label: t("managment"),
            isHeader: true,
        },
        {
            id: "users",
            label: t("users"),
            icon: "ri-user-settings-line",
            link: "/users",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "companies",
            label: t("companies"),
            icon: "ri-building-line",
            link: "/companies",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            id: "settings",
            label: t("settings"),
            icon: "ri-settings-line",
            link: "/settings",
            click: function (e) {
                e.preventDefault();
            }
        },
        {
            label: t("session"),
            isHeader: true,
        },
        /*{
            id: "lockScreen",
            label: "Lock Screen",
            link: "/#",
            icon: "ri-shield-line",
            isChildItem: true,
            click: function (e) {
                e.preventDefault();
                setIsLockScreen(!isLockScreen);
            },
            parentId: "authentication",
            stateVariables: isLockScreen,
            link: "/auth-lockscreen"
        },*/
        {
            id: "logout",
            label: t("logout"),
            link: "/#",
            icon: "ri-logout-box-line",
            isChildItem: true,
            click: function (e) {
                e.preventDefault();
                setIsLogout(!isLogout);
            },
            parentId: "authentication",
            stateVariables: isLogout,
            link: "/logout"
        },
    ];
    if(hasGroup(['Company Manager'])){
        const filteredMenuItems = menuItems.filter((x) => x.id !== "start" && x.id !== "dashboard" && x.id !== "webservices" && x.id !== "managment" && x.id !== "companies" && x.id !== "settings" );
        return <React.Fragment>{filteredMenuItems}</React.Fragment>;
    } else {
        return <React.Fragment>{menuItems}</React.Fragment>;
    }
    
};
export default Navdata;