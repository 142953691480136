import React from 'react';
import { Routes, Route } from "react-router-dom";


//Layouts
import NonAuthLayout from "../Components/Layouts/NonAuthLayout";
import VerticalLayout from "../Components/Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';

import DashboardDetail from "../Pages/Dashboard/DashboardDetail";

import Devices from "../Pages/Devices/index";
import DeviceDetail from "../Pages/Devices/DeviceDetail";
//import UpdateDevice from "../Pages/Devices/UpdateDevice";

import WebServices from "../Pages/WebServices/index";
import WebServiceDetail from "../Pages/WebServices/WebServiceDetail";
//import UpdateWebService from "../Pages/WebServices/UpdateWebService";

import Users from "../Pages/Users/index";
import UserProfile from "../Pages/Users/UserProfile";
import EditUser from "../Pages/Users/EditUser";

import Companies from "../Pages/Companies/index";
import CompanyDetail from "../Pages/Companies/CompanyDetail";

import SystemSettings from "../Pages/Settings/SystemSettings";


const Index = () => {
	return (
		<React.Fragment>
			<Routes>
				<Route>
					{publicRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<NonAuthLayout>
									{route.component}
								</NonAuthLayout>
							}
							key={idx}
							exact={true}
						/>
					))}
				</Route>

				<Route>
					{authProtectedRoutes.map((route, idx) => (
						<Route
							path={route.path}
							element={
								<AuthProtected>
									<VerticalLayout>{route.component}</VerticalLayout>
								</AuthProtected>}
							key={idx}
							exact={true}
						/>
					))}

					<Route 
						path="dashboard/"
						element={
							<AuthProtected>
								<VerticalLayout><DashboardDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route 
						path="devices/"
						element={
							<AuthProtected>
								<VerticalLayout><Devices /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route 
						path="devices/:id"
						element={
							<AuthProtected>
								<VerticalLayout><DeviceDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					{/*<Route
						path="devices/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><UpdateDevice /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>*/}
					
					<Route 
						path="users/"
						element={
							<AuthProtected>
								<VerticalLayout><Users /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route
						path="users/:id"
						element={
							<AuthProtected>
								<VerticalLayout><UserProfile /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route
						path="users/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><EditUser /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route 
						path="companies/"
						element={
							<AuthProtected>
								<VerticalLayout><Companies /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route 
						path="companies/:id"
						element={
							<AuthProtected>
								<VerticalLayout><CompanyDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route 
						path="web-services/"
						element={
							<AuthProtected>
								<VerticalLayout><WebServices /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					<Route 
						path="web-services/:id"
						element={
							<AuthProtected>
								<VerticalLayout><WebServiceDetail /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>

					{/*<Route
						path="web-services/:id/edit"
						element={
							<AuthProtected>
								<VerticalLayout><UpdateWebService /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>*/}

					<Route 
						path="settings/"
						element={
							<AuthProtected>
								<VerticalLayout><SystemSettings /></VerticalLayout>
							</AuthProtected>
						}
						exact={true}
					/>
				</Route>
			</Routes>
		</React.Fragment>
	);
};

export default Index;