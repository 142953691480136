import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Commons/BreadCrumb';
import DeviceSummary from "./DeviceSummary";
import DeviceIntegrations from "./DeviceIntegrations";
import { useTranslation, initReactI18next } from "react-i18next";

const DeviceDetail = () => {

	const { t } = useTranslation();

	document.title = t("WebService Data Replication");

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Device Details")} pageTitle={t("Devices")} />
					<Row>
						<Col xxl={3}>
							<DeviceSummary />
						</Col>
						<Col xxl={9}>
							<DeviceIntegrations />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default DeviceDetail;