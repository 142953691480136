import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from './thunk';
import { importOrders } from './thunk';

export const initialState = {
  orders: null,
  isOrderSuccess: false,
  isOrderLoading: false,
  error: null,

  importedOrders: null,
  isImportSuccess: true,
  isOrderLoading: false,
  importErrors: {},
};

const OrdersSlice = createSlice({
  name: 'Orders',
  initialState,
  reducer: {},
  extraReducers: (builder) => {

    /* Get Orders */
    builder.addCase(getOrders.fulfilled, (state, action) => {
      console.log("-> fulfilled ");
      state.orders = action.payload;
      state.isOrderSuccess = true;
      state.isOrderLoading = false;
    });

    builder.addCase(getOrders.pending, (state, action) => {
      console.log("-> pending ");
      state.orders = [];
      state.isOrderSuccess = false;
      state.isOrderLoading = true;
    });

    builder.addCase(getOrders.rejected, (state, action) => {
      console.log("-> rejected ");
      state.orders = [];
      state.error = action.error || null;
      state.isOrderSuccess = false;
      state.isOrderLoading = false;
    });

    /* Import Orders */
    builder.addCase(importOrders.fulfilled, (state, action) => {
      state.importedOrders = action.payload;
      state.isImportSuccess = true;
    });

    builder.addCase(importOrders.rejected, (state, action) => {
      state.importedOrders = [];
      state.error = action.error || null;
      state.isImportSuccess = false;  
    });

  }
});

export default OrdersSlice.reducer;