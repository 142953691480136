import React, { useState, useMemo, useCallback, useEffect } from "react";

import { Button } from "reactstrap";

import * as moment from "moment";
import { Link, useParams } from "react-router-dom";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import DeleteModal from "../../Components/Commons/DeleteModal";
import IntegrationStatus from "./IntegrationStatus";
import { toast } from 'react-toastify';
import { useTranslation, initReactI18next } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useDisableDeviceIntegrationMutation,
} from '../../api/api'

const IntegrationsTable = ({
	data,
	isLoading,
	isSuccess,
	error,
	onDetailsPressed,
}) => {

	let { id } = useParams();
	const { t } = useTranslation();

	const [integration, setIntegration] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);

	const onClickDelete = (integration) => {
		setIntegration(integration);
		setDeleteModal(true);
	};

	const [disableIntegrateDevice, 
	{ data: disableObject, 
		error: disableErrors, 
		isLoading: isDisableLoading, 
		isSuccess: isDisableSuccess
	}] = useDisableDeviceIntegrationMutation();

	useEffect(() => {
		if (isDisableSuccess) {
			setDeleteModal(false);
			toast.success(t(`Integration disabled successfully`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
		if (disableErrors) {
			setDeleteModal(false);
			toast.error(t(`There was an error while disabling the integration`), {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				bodyClassName: "fs-24",}
			);
		}
	  }, [isDisableSuccess, disableErrors]);


	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const handleDelete = useCallback(() => {
		if (integration) {
			const body = {
				web_service_id: integration.web_service.id
			};
			disableIntegrateDevice({id:id, body:body});
		}
	}, [integration]);

	const toggle = useCallback((integration) => {
		onDetailsPressed(integration);
	});

	// Column
	const columns = useMemo(
		() => [
			{
				Header: "WebService",
				filterable: false,
				Cell: (cell) => {
					return <Link to={`../web-services/${cell.row.original.web_service.id}`} className="fw-medium link-primary h5">{cell.row.original.web_service.name}</Link>;
				},
			},
			{
				Header: t("Last Update Date"),
				accessor: "last_update_date",
				Cell: (integration) => {
					if(integration.row.original.last_update_date)
						return <>
							{handleValidDate(integration.row.original.last_update_date)},
							<small className="text-muted"> {handleValidTime(integration.row.original.last_update_date)}</small>
						</>
					else
						return <></>
				},
			},
			{
				Header: t("Last success attemp"),
				accessor: "last_write_date",
				Cell: (integration) => {
					if(integration.row.original.last_write_date)
						return <>
							{handleValidDate(integration.row.original.last_write_date)},
							<small className="text-muted"> {handleValidTime(integration.row.original.last_write_date)}</small>
						</>
					else
						return <></>
				},
			},
			{
				Header: t("Last fail attemp"),
				accessor: "last_write_attempt_date",
				Cell: (integration) => {
					if(integration.row.original.last_write_attempt_date)
						return <>
							{handleValidDate(integration.row.original.last_write_attempt_date)},
							<small className="text-muted"> {handleValidTime(integration.row.original.last_write_attempt_date)}</small>
						</>
					else
						return <></>
				},
			},
			{
				Header: t("Last GPS Datetime"),
				accessor: "last_gps_datetime",
				Cell: (integration) => {
					if(integration.row.original.last_gps_datetime){
						return <>
							{handleValidDate(integration.row.original.last_gps_datetime)},
							<small className="text-muted"> {handleValidTime(integration.row.original.last_gps_datetime)}</small>
						</>;
					} else {
						return <></>;
					}
				},
			},
			{
				Header: t('Status'),
				accessor: 'status',
				Cell: (integration) => {
					return <IntegrationStatus status={integration.row.original.status} status_text={integration.row.original.status_text} />;
				}
			},
			{
				Header: t("Action"),
				Cell: (cellProps) => {
					return (
						<ul className="list-inline hstack gap-2 mb-0">
							<Button 
								color="link" 
								onClick={() => {
									const integrationData = cellProps.row.original;
									toggle(integrationData);
								}} 
								className="" 
								type="button" 
								id="password-addon"
							><i className="ri-eye-fill align-middle"></i>
							</Button>
							<li className="list-inline-item">
								<Link
									to="#"
									className="text-danger d-inline-block remove-item-btn"
									onClick={() => {
										const integrationData = cellProps.row.original;
										onClickDelete(integrationData);
									}}
								>
									<i className="ri-delete-bin-5-fill fs-16"></i>
								</Link>
							</li>
						</ul>
					);
				},
			},
		],
		[integration]
	);

	return (
		<>
			<DeleteModal
				show={deleteModal}
				onDeleteClick={handleDelete}
				onCloseClick={() => setDeleteModal(false)}
			/>
			<OrdersTableBase
				columns={columns}
				data={data}
				isLoading={isLoading}
				isSuccess={isSuccess}
				error={error}
				manualPagination={true}
				customPageSize={25}
				divClass="table-responsive table-card mb-1"
				tableClass="align-middle table-nowrap"
				theadClass="table-light text-muted"
			/>
		</>
	);
};

export default IntegrationsTable;

